import React from 'react';
import { MainMenu } from '@/features';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, TypographyFace } from '@/types';
import decor from '@/images/about-decor.png';

type Props = {
  title: string;
  secondarySubTitle: string;
  mainSubTitle: string;
};

export const TopSide: React.FC<Props> = ({ secondarySubTitle, mainSubTitle, title }) => {
  return (
    <div className="about__top-side">
      <MainMenu />
      <Container>
        <Row>
          <Col>
            <Typography variant={TypographyFace.header} accent={Accent.light} className="about__top-title">
              {title}
            </Typography>

            <div className="about__sub-title">
              <Typography variant={TypographyFace.sub_header} accent={Accent.gray_01}>
                {secondarySubTitle}
              </Typography>
              <Typography variant={TypographyFace.sub_header} accent={Accent.light}>
                {mainSubTitle}
              </Typography>
            </div>

            <img className="about__decor" src={decor} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};
