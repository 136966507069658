import { createEvent, createStore } from 'effector';

const loadError$ = createStore<Error | null>(null);

const setError = createEvent<Error>();
const resetError = createEvent();

loadError$.on(setError, (s, e) => e);
loadError$.reset(resetError);

export { setError, resetError, loadError$ };
