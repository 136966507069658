import React from 'react';
import { Col, Row, Typography } from '@/layout';
import { Indicator } from '@/types/blocks';
import { Accent, ColType, TypographyFace } from '@/types';
import './index.less';

type Props = {
  indicators: Indicator[];
};

export const InfoBar: React.FC<Props> = ({ indicators }) => {
  return (
    <Row className="info-bar" wrap>
      {indicators.map(({ value, label }, i) => {
        return (
          <Col type={ColType.equal_sm} key={i} className="info-bar__block">
            <Typography accent={Accent.blue_03} className="info-bar__value">
              {value}
            </Typography>
            <Typography variant={TypographyFace.body_m}>{label}</Typography>
          </Col>
        );
      })}
    </Row>
  );
};
