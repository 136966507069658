import React from 'react';

import { Col, Container, Row, Typography } from '@/layout';
import { H2WithInfoBlock, List, OrderBlock } from '@/blocks';
import { ColType } from '@/types/common';
import { FoundationYearsLogo, TopSide } from './Blocks';
import './index.less';
import { InfoBar } from '@/pages/About/Blocks/InfoBar/InfoBar';
import { NewsWidget } from '@/features';
import { useStore } from 'effector-react';
import { about$ } from '@/models/aboutPage';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';

export const About: React.FC = () => {
  const { data } = useStore(about$.store);

  useLoadData(about$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <TopSide
        title={data.headerTitle}
        secondarySubTitle={data.headerSecondarySubTitle}
        mainSubTitle={data.headerMainSubTitle}
      />
      <Container className="about__container">
        <Row align="start" className="about__logo-row">
          <FoundationYearsLogo />
          <Col type={ColType.content}>
            <Typography markdown>{data.aboutInfo}</Typography>
          </Col>
        </Row>

        <Row align="baseline">
          <Col type={ColType.side} grow className="about__info-grow-col" />
          <Col type={ColType.content}>
            {data.history.map((v, k) => (
              <H2WithInfoBlock key={k} header={v.year} info={v.text} />
            ))}
          </Col>
        </Row>

        <Row align="baseline" wrap={true}>
          <Col type={ColType.side}>
            <h1>ПРЕДЛАГАЕМ ВАШЕМУ ВНИМАНИЮ СЛЕДУЮЩИЙ СПЕКТР УСЛУГ</h1>
          </Col>
          <Col type={ColType.content}>
            <List
              className="about__products"
              items={[
                ...data.products.map((v) => ({ label: v.text })),
                { label: 'Будем рады видеть Вас в числе наших клиентов!' },
              ]}
            />
          </Col>
        </Row>

        <InfoBar indicators={data.indicators} />

        <NewsWidget />

        <Row align="start" wrap className="about__vacancies">
          <Col type={ColType.side}>
            <h1>НАШИ ВАКАНСИИ</h1>
          </Col>
          <Col type={ColType.content}>
            <Typography>
              <p>
                Если Вы доброжелательны, целеустремленны, талантливы, мечтаете работать в дружном коллективе, получать
                стабильную заработную плату, добро пожаловать в компанию «АВИВ Групп».
              </p>
              <br />
              <p>
                <strong>
                  Ждём Ваши резюме по электронной почте pcb@aviv-group.ru, либо звоните по телефону +7 (495) 666-44-78
                </strong>
              </p>
            </Typography>
          </Col>
        </Row>
        <OrderBlock />
      </Container>
    </>
  );
};
