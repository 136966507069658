import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { contractsCables$ } from '@/models/contractsCablesPage';
import { List, TopHeader } from '@/blocks';
import { Col, Container, Row } from '@/layout';
import './index.less';
import { ColType } from '@/types';
import { getImagePropsFromResponse } from '@/utils';

export const Cables: React.FC = () => {
  const { data } = useStore(contractsCables$.store);

  useLoadData(contractsCables$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }
  return (
    <>
      <TopHeader title="ИЗГОТОВЛЕНИЕ ЖГУТОВ И КАБЕЛЬНОЙ ПРОДУКЦИИ" className="contracts-cables__header">
        <div className="contracts-cables__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <Col type={ColType.equal_lg}>
            <List items={data.possibilities} header="НАШИ ВОЗМОЖНОСТИ" />
          </Col>
          <Col type={ColType.equal_lg}>
            <img {...getImagePropsFromResponse(data.assets.data.find((v) => v.attributes.caption === 'top') ?? null)} />
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <img
              {...getImagePropsFromResponse(data.assets.data.find((v) => v.attributes.caption === 'materials') ?? null)}
            />
          </Col>
          <Col type={ColType.equal_lg}>
            <List
              items={data.materials}
              header="РАСХОДНЫЕ МАТЕРИАЛЫ И КОМПЛЕКТУЮЩИЕ ДЛЯ ИЗГОТОВЛЕНИЯ ЖГУТОВ И КАБЕЛЕЙ"
            />
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <List
              items={data.dataToPlaceOrder}
              header="Для того чтобы сделать заказ, необходимо предоставить нам необходимые данные"
            />
          </Col>
          <Col type={ColType.equal_lg} />
        </Row>
      </Container>
    </>
  );
};
