/* eslint-disable react-hooks/exhaustive-deps */

import React from 'react';
import { Event } from 'effector';

export function useLoadData<T extends Function>({
  load,
  reset,
  needReset,
}: {
  reset: Event<void>;
  load: T;
  needReset?: boolean;
}) {
  React.useEffect(() => {
    load();
    if (needReset) {
      return () => {
        reset();
      };
    }
  }, []);
}
