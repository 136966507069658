import React from 'react';
import { useStore } from 'effector-react';
import { news$ } from '@/models/news';
import { useLoadData } from '@/hooks/useLoadData';
import { Col, Container, Row, Typography } from '@/layout';
import { getImagePropsFromResponse, take } from '@/utils';
import { Accent, ColType, TypographyFace } from '@/types';
import { Button, Link } from '@/components';
import { newsCount } from '@/constants';
import './index.less';

export const News: React.FC = () => {
    const { data } = useStore(news$.store);
    const [count, setCount] = React.useState(newsCount);

    useLoadData(news$.methods);

    return (
        <Container>
            <Row>
                <Col type={ColType.content}>
                    <Typography variant={TypographyFace.header}>Новости</Typography>
                </Col>
            </Row>
            {take(data, count).map((event) => {
                const date = new Date(event.date);

                return (
                    <Row key={event.title} className="news__row">
                        <Col type={ColType.content}>
                            <h2 className="news__title">{event.title}</h2>
                            <div>
                                <Typography markdown markdownDisallowedElements={['p']} className="news__text">
                                    {event.content.trim()}
                                </Typography>
                                {" "}
                                <Link to={`${event.id}`} className="news__more_link">
                                    Подробнее
                                </Link>
                            </div>
                            <div className="news__date">
                                {date.getDate()}.{date.getMonth() + 1}.{date.getFullYear()}
                            </div>
                        </Col>
                        <Col type={ColType.side}>
                            <img {...getImagePropsFromResponse(event.asset.data)} />
                        </Col>
                    </Row>
                );
            })}
            {data.length > count && (
                <Row justify="center">
                    <Button className="news__button" onClick={() => setCount((c) => c + 1)} accent={Accent.light}>
                        ЗАГРУЗИТЬ ЕЩЁ
                    </Button>
                </Row>
            )}
        </Container>
    );
};
