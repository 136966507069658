import React, { PropsWithChildren } from 'react';
import Slider, { Settings } from 'react-slick';

import './index.less';
import { IconButton } from '@/components';
import { Accent } from '@/types';
import { ArrowLeft, ArrowRight } from '@/icons';

type Props = {
    title: string;
    itemsCount: number;
};

export const Carousel: React.FC<PropsWithChildren<Props>> = ({ children, title, itemsCount }) => {
    const ref = React.useRef<Slider | null>(null);

    const settings: Settings = {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: itemsCount >= 4 ? 4 : itemsCount - 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        onInit() {
            setTimeout(() => ref.current?.slickGoTo(0), 1000);
        },
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: itemsCount >= 4 ? 4 : itemsCount - 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: itemsCount >= 2 ? 2 : itemsCount - 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleClickNext = () => {
        ref.current?.slickNext();
    };

    const handleClickPrev = () => {
        ref.current?.slickPrev();
    };

    return (
        <div className="carousel">
            <div className="carousel__top">
                <h1>{title}</h1>
                <div className="carousel__buttons">
                    <IconButton
                        className="carousel__button"
                        onClick={handleClickPrev}
                        accent={Accent.dark}
                        icon={ArrowLeft}
                    />
                    <IconButton
                        className="carousel__button"
                        onClick={handleClickNext}
                        accent={Accent.dark}
                        icon={ArrowRight}
                    />
                </div>
            </div>
            <Slider {...settings} ref={(instance) => (ref.current = instance)} className="carousel__slides">
                {children}
            </Slider>
        </div>
    );
};
