import React from 'react';
import { news$ } from '@/models/news';
import { useStore } from 'effector-react';
import { Typography } from '@/layout';
import { Carousel } from '@/blocks';
import { Accent, TypographyFace } from '@/types';
import { getImagePropsFromResponse } from '@/utils';
import { Link } from '@/components';
import { useLoadData } from '@/hooks/useLoadData';
import './index.less';

export const NewsWidget: React.FC = () => {
  const { data: news } = useStore(news$.store);
  useLoadData(news$.methods);

  return (
    <div className="news-widget">
      <Carousel title="НОВОСТИ КОМПАНИИ" itemsCount={news.length}>
        {news.map((v) => {
          return (
            <div className="news-widget__item" key={v.id}>
              <img {...getImagePropsFromResponse(v.asset.data)} />
              <Link to={`news/${v.id}`}>
                <h2 className="news-widget__title">{v.title}</h2>
              </Link>
              <Typography variant={TypographyFace.body_s}>{v.date}</Typography>
            </div>
          );
        })}
      </Carousel>
      <Link to="news" className="news-widget__archive">
        <Typography variant={TypographyFace.body_s} accent={Accent.blue_02}>
          Архив новостей
        </Typography>
      </Link>
    </div>
  );
};
