import React from 'react';
import cn from 'classnames';

import { BaseButtonProps, Button } from '@/components';
import './index.less';

type IconProps = {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};
export const IconButton: React.FC<BaseButtonProps & IconProps> = ({ icon, className = '', ...rest }) => {
  const Icon = icon;
  const classes = cn('button-icon', { [className]: !!className, [`button-icon-${rest.accent}`]: true });

  return (
    <Button className={classes} {...rest}>
      <Icon />
    </Button>
  );
};
