import React from 'react';
import { useStore } from 'effector-react';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType, TypographyFace } from '@/types';
import './layered.less';
import { PcbTypesBlock } from '@/blocks/PcbTypesBlock/PcbTypesBlock';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { pcbPlanar$ } from '@/models/pcbPlanar';

export const PcbPlanar: React.FC = () => {
    const { data } = useStore(pcbPlanar$.store);

    useLoadData(pcbPlanar$.methods);

    if (!data) {
        return <Loader fullWidth />;
    }

    return (
        <Container>
            <Row>
                <Col type={ColType.equal_lg}>
                    <Typography variant={TypographyFace.header}>ПЛАНАРНЫЕ ТРАНСФОРМАТОРЫ</Typography>
                </Col>
            </Row>
            <Row wrap>
                {data.content.split('\n\n').map((paragraph, i) => {
                    return (
                        <Col type={ColType.equal_lg} key={i}>
                            <Typography markdown>{paragraph}</Typography>
                        </Col>
                    );
                })}
            </Row>
            <PcbTypesBlock type="types" />
        </Container>
    );
};
