import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { contractsDelivery$ } from '@/models/contractsDeliveryPage';
import { List, TopHeader } from '@/blocks';
import { Col, Container, Row, Typography } from '@/layout';
import './index.less';
import { ColType } from '@/types';
import { getImagePropsFromResponse } from '@/utils';

export const Delivery: React.FC = () => {
  const { data } = useStore(contractsDelivery$.store);

  useLoadData(contractsDelivery$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <TopHeader title="КОМПЛЕКСНАЯ ПОСТАВКА ЭЛЕКТРОННЫХ КОМПОНЕНТОВ">
          <div className="contracts-delivery__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.info}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            <div className="contracts-delivery__images-container">
              {data.assets.data.map((asset) => (
                <img className="contracts-delivery__img" key={asset.id} {...getImagePropsFromResponse(asset)} />
              ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <h1>ОСНОВНЫЕ ВОЗМОЖНОСТИ ОТДЕЛА КОМПЛЕКТАЦИИ</h1>
          </Col>
          <Col type={ColType.equal_lg}>
            <List items={data.possibilities} />
          </Col>
        </Row>
      </Container>
    </>
  );
};
