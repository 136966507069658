import { ReactComponent as ArrowRight } from './arrowRight.svg';
import { ReactComponent as ArrowLeft } from './arrowLeft.svg';
import { ReactComponent as ArrowBottom } from './arrowBottom.svg';
import { ReactComponent as ArrowUp } from './arrowUp.svg';
import { ReactComponent as AvivLogo } from './logo.svg';
import { ReactComponent as MenuIcon } from './menu.svg';
import { ReactComponent as ChevronBottom } from './chevronBottom.svg';
import { ReactComponent as CloseIcon } from './close.svg';
import { ReactComponent as CircleCloseIcon } from './circleClose.svg';
import { ReactComponent as ViewIcon } from './view.svg';
import { ReactComponent as CircleProgressIcon } from './circleProgress.svg';

export {
  ArrowRight,
  ArrowLeft,
  CircleCloseIcon,
  AvivLogo,
  MenuIcon,
  ChevronBottom,
  ArrowBottom,
  ArrowUp,
  CloseIcon,
  ViewIcon,
  CircleProgressIcon,
};
