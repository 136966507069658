import React from 'react';
import './index.less';

type Props = {
  fullWidth?: boolean;
};

export const Loader: React.FC<Props> = ({ fullWidth = false }) => {
  if (fullWidth) {
    return (
      <div className="loader__container">
        <div className="loader" />
      </div>
    );
  }
  return <div className="loader" />;
};
