import { api, ENDPOINTS } from '@/api/api';
import { Asset, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type NewsPageDataDto = {
  title: string;
  offices: {
    data: Array<ResponseType<{ name: string; address: string; map: ResponseDataType<Asset>; embedded: string }>>;
  };
};

const loadContactsPage = async () => {
  const data = await api.get<ResponseDataType<NewsPageDataDto>>(ENDPOINTS.pages.contacts, {
    params: { populate: 'offices.map' },
  });
  const res = getResponseData(data);
  return { title: res.title, offices: res.offices.data.map((v) => v.attributes) };
};

const contacts$ = fetchDataFactory({ defaultData: null, loader: loadContactsPage });

export { contacts$ };
