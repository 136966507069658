import React from 'react';
import cn from 'classnames';

import { Row } from '@/layout';
import { IconButton, Link } from '@/components';
import { ArrowBottom, ArrowUp, AvivLogo } from '@/icons';
import { Accent, Route } from '@/types';
import { mainRoutes } from '@/pages/routes';
import { useLocation, Link as RouterLink } from 'react-router-dom';

export const PCMenu: React.FC = () => {
    const [subMenu, setSubMenu] = React.useState<(Route & { expand: string[] }) | null>(null);
    const subMenuRef = React.useRef<HTMLDivElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const location = useLocation();

    const subMenuClasses = cn('menu__submenu_root', { [`menu__submenu_root-show-${!!subMenu}`]: true });

    const hasChildren = (children?: Route['children']) => !!children?.length;

    const handleShowSubMenu = (item: Route) => {
        if (!hasChildren(item.children)) {
            setSubMenu(null);
            return;
        }
        setSubMenu({ ...item, expand: [] });
    };

    const handleHideSubMenu = (e: React.MouseEvent) => {
        if (e.relatedTarget instanceof Node && subMenuRef.current?.contains(e.relatedTarget)) {
            return;
        }
        setSubMenu(null);
    };

    const expandMenuToggle = (path: string, e?: React.MouseEvent<unknown>) => {
        if (!subMenu) {
            return;
        }

        e?.preventDefault();

        setSubMenu({
            ...subMenu,
            expand: subMenu.expand.includes(path)
                ? subMenu.expand.filter((v) => v !== path)
                : [...subMenu.expand, path],
        });
    };

    return (
        <div className="menu__root">
            <RouterLink to="/">
                <AvivLogo />
            </RouterLink>
            <div className="menu__container" ref={containerRef}>
                {mainRoutes.map((item, i) => {
                    if (item.hideInMenu) {
                        return null;
                    }
                    return (
                        <div
                            className={cn('menu__item', {
                                'menu__item-side-menu': !!subMenu,
                                'menu__item-selected': subMenu?.path === item.path || location.pathname === item.path,
                            })}
                            onMouseOver={() => handleShowSubMenu(item)}
                            key={i}
                        >
                            <Link to={`${item.path}`} theme={Accent.dark}>
                                {item.label}
                            </Link>
                        </div>
                    );
                })}
                <Row
                    className={subMenuClasses}
                    onMouseLeave={handleHideSubMenu}
                    ref={subMenuRef}
                    style={{ minWidth: containerRef.current && subMenu ? containerRef.current.clientWidth : 0 }}
                >
                    <div className="menu__sub-item-container">
                        {subMenu?.children?.map(({ label, path, children }) => {
                            return (
                                <React.Fragment key={path}>
                                    <div className="menu__sub-item">
                                        <Link
                                            to={`${path}`}
                                            className="menu__sub-item-link"
                                            onClick={(e) => children && path && expandMenuToggle(path, e)}
                                        >
                                            {label}
                                        </Link>
                                        {children && path && (
                                            <IconButton
                                                className="menu__sub-item__button"
                                                accent={Accent.light}
                                                icon={path && subMenu?.expand?.includes(path) ? ArrowUp : ArrowBottom}
                                            />
                                        )}
                                    </div>
                                    {path &&
                                        subMenu?.expand?.includes(path) &&
                                        subMenu?.children
                                            ?.find((v) => v.path === path)
                                            ?.children?.map((child) => {
                                                return (
                                                    <div className="menu__sub-item" key={child.path}>
                                                        <Link
                                                            to={`${child.path}`}
                                                            className="menu__sub-item-link menu__sub-item-link-expanded"
                                                        >
                                                            {child.label}
                                                        </Link>
                                                    </div>
                                                );
                                            })}
                                </React.Fragment>
                            );
                        })}
                    </div>
                </Row>
            </div>
        </div>
    );
};
