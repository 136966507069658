import { RouteObject } from 'react-router-dom';

export enum Accent {
  light = 'light',
  dark = 'dark',
  error = 'error',
  success = 'success',
  gray_01 = 'gray_01',
  gray_02 = 'gray_02',
  gray_03 = 'gray_03',
  blue_01 = 'blue_01',
  blue_02 = 'blue_02',
  blue_03 = 'blue_03',
}

export enum ColType {
  equal_lg = 'equal-lg',
  equal_md = 'equal-md',
  equal_sm = 'equal-sm',
  side = 'side',
  small_side = 'small_side',
  content = 'content',
  full_width = 'full_width',
}

export enum TypographyFace {
  header = 'header',
  sub_header = 'sub-header',
  body = 'body',
  body_l = 'body-l',
  body_m = 'body-m',
  body_s = 'body-s',
  body_xs = 'body-xs',
}

export interface Route extends RouteObject {
  label: string;
  children?: Route[];
  hideInMenu?: boolean;
}

export type ResponseType<T> = {
  id: number;
  attributes: T;
};

export type ResponseDataType<T> = {
  data: ResponseType<T>;
};
