import React, {forwardRef} from "react";
import cn from 'classnames';
import './index.less';
import {FieldError} from "react-hook-form/dist/types/errors";

type SelectProps = React.InputHTMLAttributes<HTMLSelectElement> & {
    label?: string;
    block?: boolean;
    errors?: Record<string, FieldError>
};
// eslint-disable-next-line react/display-name
export const Select = forwardRef<HTMLSelectElement, SelectProps>(({
                                                                      label,
                                                                      className,
                                                                      placeholder,
                                                                      block,
                                                                      errors,
                                                                      children,
                                                                      ...props
                                                                  }, ref) => {

    const wrapperClassName = cn(
            "select",
            className,
            {
                "select--block": block,
                "select--error": !!(props.name && errors?.[props.name])
            }
    );

    return <div className={wrapperClassName}>
        {!!label && <div className="select__label">{label}</div>}
        <select {...props} ref={ref}>
            {!!placeholder && <option disabled selected={!props.value} value="">{placeholder}</option>}
            {children}
        </select>


        {props.name && errors?.[props.name] && <div className="text_input__error">
            {errors[props.name].message}
        </div>}
    </div>;

});