import React from 'react';

import { About } from '@/pages/About/About';
import { Route } from '@/types';
import { Pcb } from '@/pages/PCB/Pcb';
import { PcbTech } from '@/pages/PCBTech/PcbTech';
import { PcbMaterials } from '@/pages/PCBMaterials/PcbMaterials';
import { PcbLayered } from '@/pages/PCBTypes/pages/PcbLayered';
import { News } from '@/pages/News/News';
import { Contacts } from '@/pages/Contacts/Contacts';
import { Contracts } from '@/pages/Contracts/Contracts';
import { Mount } from '@/pages/Contracts/Mount/Mount';
import { Project } from '@/pages/Contracts/Project/Project';
import { Delivery } from '@/pages/Contracts/Delivery/Delivery';
import { Cables } from '@/pages/Contracts/Cables/Cables';
import { Mechanic } from '@/pages/Contracts/Mechanic/Mechanic';
import { Winding } from '@/pages/Contracts/Winding/Winding';
import { Protection } from '@/pages/Contracts/Protection/Protection';
import { Order } from '@/pages/Order/Order';
import { PcbSides } from '@/pages/PCBTypes/pages/PcbSides';
import { PcbFlex } from '@/pages/PCBTypes/pages/PcbFlex';
import { PcbFrequency } from '@/pages/PCBTypes/pages/PcbFrequency';
import { PcbHeatSink } from '@/pages/PCBTypes/pages/PcbHeatSink';
import { PcbPlanar } from './PCBTypes/pages/PcbPlanar';
import {ScreensForSMT} from "@/pages/Contracts/ScreensForSMT/ScreensForSMT";
import {OrderFormPage} from "@/pages/Order/OrderFormPage";

export const pcbTypeRoutes: Route[] = [
    {
        path: '/pcb/type/sides',
        element: <PcbSides />,
        label: 'Одно- и двусторонние печатные платы',
    },
    {
        path: '/pcb/type/layered',
        element: <PcbLayered />,
        label: 'Многослойные печатные платы',
    },
    {
        path: '/pcb/type/flex',
        element: <PcbFlex />,
        label: 'Гибкие и гибко-жёсткие печатные платы',
    },
    {
        path: '/pcb/type/frequency',
        element: <PcbFrequency />,
        label: 'Высокочастотные печатные платы',
    },
    {
        path: '/pcb/type/heat-sink',
        element: <PcbHeatSink />,
        label: 'Платы со встроенным теплоотводом',
    },
    {
        path: '/pcb/type/planar',
        element: <PcbPlanar />,
        label: 'Планарные трансформаторы',
    },
];

export const pcbRoutes: Route[] = [
    {
        path: '/pcb/tech',
        element: <PcbTech />,
        label: 'ТЕХНОЛОГИЧЕСКИЕ ВОЗМОЖНОСТИ ПРОИЗВОДСТВ',
    },
    {
        path: '/pcb/materials',
        element: <PcbMaterials />,
        label: 'ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ',
    },
    {
        path: '/pcb/type/layered',
        element: <PcbLayered />,
        label: 'ТИПЫ ПЕЧАТНЫХ ПЛАТ',
        children: pcbTypeRoutes,
    },
];

export const contractsRoutes: Route[] = [
    {
        path: '/contracts/mount',
        element: <Mount />,
        label: 'МОНТАЖ ПЕЧАТНЫХ ПЛАТ',
    },
    {
        path: '/contracts/project',
        element: <Project />,
        label: 'ПРОЕКТИРОВАНИЕ ПЕЧАТНЫХ ПЛАТ И ЭЛЕКТРОННЫХ МОДУЛЕЙ',
    },
    {
        path: '/contracts/delivery',
        element: <Delivery />,
        label: 'КОМПЛЕКСНАЯ ПОСТАВКА ЭЛЕКТРОННЫХ КОМПОНЕНТОВ',
    },
    {
        path: '/contracts/cable',
        element: <Cables />,
        label: 'ИЗГОТОВЛЕНИЕ ЖГУТОВ И КАБЕЛЬНОЙ ПРОДУКЦИИ',
    },
    {
        path: '/contracts/mechanic',
        element: <Mechanic />,
        label: 'МЕХАНИЧЕСКОЕ ПРОИЗВОДСТВО И ЛИТЬЁ ПЛАСТМАСС',
    },
    {
        path: '/contracts/winding',
        element: <Winding />,
        label: 'ИЗГОТОВЛЕНИЕ МОТОЧНЫХ ИЗДЕЛИЙ',
    },
    {
        path: '/contracts/protection',
        element: <Protection />,
        label: 'ВЛАГОЗАЩИТА ПЕЧАТНЫХ УЗЛОВ',
    },
    {
        path: '/contracts/screens-for-smt',
        element: <ScreensForSMT />,
        label: 'ТРАФАРЕТЫ ДЛЯ SMT МОНТАЖА',
    },
];

export const mainRoutes: Route[] = [
    {
        path: '/',
        element: <About />,
        label: 'О КОМПАНИИ',
    },
    {
        path: '/pcb',
        element: <Pcb />,
        label: 'ПЕЧАТНЫЕ ПЛАТЫ',
        children: pcbRoutes,
    },
    {
        path: '/contracts',
        element: <Contracts />,
        label: 'КОНТРАКТНОЕ ПРОИЗВОДСТВО',
        children: contractsRoutes,
    },
    {
        path: '/order',
        element: <Order />,
        label: 'ОФОРМЛЕНИЕ ЗАКАЗА',
    },
    {
        path: '/order/form',
        element: <OrderFormPage />,
        label: 'ОФОРМЛЕНИЕ ЗАКАЗА',
        hideInMenu: true,
    },
    {
        path: '/contacts',
        element: <Contacts />,
        label: 'КОНТАКТЫ',
    },
    {
        path: '/news',
        element: <News />,
        label: 'Новости',
    },
];

export const routes = [...mainRoutes, ...pcbRoutes, ...pcbTypeRoutes, ...contractsRoutes];
