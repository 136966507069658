import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType, TypographyFace } from '@/types';
import { contractsScreensForSMT$ } from '@/models/contractsScreensForSMT';
import './index.less';
import {getImagePropsFromResponse} from "@/utils";

export const ScreensForSMT = () => {
    const { data } = useStore(contractsScreensForSMT$.store);

    useLoadData(contractsScreensForSMT$.methods);

    if (!data) {
        return <Loader fullWidth />;
    }

    return (
        <Container>
            <Row>
                <Col type={ColType.equal_lg}>
                    <Typography variant={TypographyFace.header}>
                        ТРАФАРЕТЫ ДЛЯ SMT МОНТАЖА
                    </Typography>
                </Col>
            </Row>
            <Row wrap>
                <Col type={ColType.equal_lg}>
                    <Typography markdown>{data.content}</Typography>

                    {data.params?.length > 0 && (
                        <Typography>
                            {'\n'}
                            <b>Основные параметры производства</b>
                            {'\n'}
                            <div className="screen-for-smt__params">
                                {data.params.map((param) => (
                                    <div key={param.id}>{param.label}</div>
                                ))}
                            </div>
                        </Typography>
                    )}
                </Col>
                <Col type={ColType.equal_lg}>
                    <Typography markdown>{data.right_side}</Typography>
                    <div className="screen-for-smt__img-container">
                        {data.pictures.map((v) => {
                                    return <img key={v.id} {...getImagePropsFromResponse(v)} />;
                                }
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};
