import React, { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowRight } from '@/icons';

import './index.less';

type Props = {
  to: string;
};

export const ForwardLink: React.FC<PropsWithChildren<Props>> = ({ to, children }) => {
  return (
    <RouterLink to={to} className="router-link">
      <>
        {children} <ArrowRight className="arrow" />
      </>
    </RouterLink>
  );
};
