import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { ResponseDataType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type AboutPageData = {
  headerTitle: string;
  headerSecondarySubTitle: string;
  headerMainSubTitle: string;
  aboutInfo: string;
  history: Array<{ year: string; text: string }>;
  products: { text: string }[];
  indicators: { value: string; label: string }[];
};

const loadAboutPage = async () => {
  const data = await api.get<ResponseDataType<AboutPageData>>(ENDPOINTS.pages.about, { params: DEFAULT_GET_PARAMS });
  return getResponseData(data);
};

const about$ = fetchDataFactory({ defaultData: null, loader: loadAboutPage });

export { about$ };
