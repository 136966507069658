import React from 'react';
import { useStore } from 'effector-react';
import { pcbLayered$ } from '@/models/pcbLayered';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType, TypographyFace } from '@/types';
import { chunkArray, getImagePropsFromResponse } from '@/utils';
import './layered.less';
import { PcbTypesBlock } from '@/blocks/PcbTypesBlock/PcbTypesBlock';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';

export const PcbLayered: React.FC = () => {
    const { data } = useStore(pcbLayered$.store);

    useLoadData(pcbLayered$.methods);

    if (!data) {
        return <Loader fullWidth />;
    }

    return (
        <Container>
            <Row>
                <Col type={ColType.equal_lg}>
                    <Typography variant={TypographyFace.header}>МНОГОСЛОЙНЫЕ ПЕЧАТНЫЕ ПЛАТЫ</Typography>
                </Col>
            </Row>
            <Row wrap>
                <Col type={ColType.equal_lg}>
                    <Typography markdown>{data.content}</Typography>
                </Col>
                <Col type={ColType.equal_lg}>
                    {data.widePicture && (
                        <img className="pcb-layered__wide-img" {...getImagePropsFromResponse(data.widePicture)} />
                    )}
                    {chunkArray(data.otherAssets, 2).map((v, index) => {
                        return (
                            <div key={index} className="pcb-layered__img-container">
                                {v.map((i) => {
                                    return <img key={i.id} {...getImagePropsFromResponse(i)} />;
                                })}
                            </div>
                        );
                    })}
                </Col>
            </Row>
            <PcbTypesBlock type="types" />
        </Container>
    );
};
