import React from 'react';
import cn from 'classnames';

import './index.less';

type Props = {
  className?: string;
  grow?: boolean;
  align?: React.CSSProperties['alignItems'];
  justify?: React.CSSProperties['justifyContent'];
  wrap?: boolean;
  block?: boolean;
};
type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Row = React.forwardRef<HTMLDivElement, Props & DivProps>(
  ({ children, className = '', align = 'start', justify = 'stretch', wrap = false, grow, ...nativeProps }, ref) => {
    const classes = cn('row', {
      [className]: !!className,
      [`row-align-${align}`]: true,
      [`row-justify-${justify}`]: true,
      [`row-wrap-${wrap}`]: true,
      'row-grow': grow,
    });
    return (
      <div {...nativeProps} className={classes} ref={ref}>
        {children}
      </div>
    );
  }
);

Row.displayName = 'Row';
