import React from 'react';
import { useStore } from 'effector-react';
import { contacts$ } from '@/models/contactsPage';
import { useLoadData } from '@/hooks/useLoadData';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import { Loader } from '@/components';
import './index.less';
import { List } from '@/blocks';
import { MainMenu } from '@/features';
import { Link as ScrollLink } from 'react-scroll';

export const Contacts: React.FC = () => {
    const { data } = useStore(contacts$.store);
    useLoadData(contacts$.methods);

    if (!data) {
        return <Loader fullWidth />;
    }

    return (
        <>
            <div className="contacts__root">
                <MainMenu />
                <Container>
                    <Row wrap={true}>
                        <Col type={ColType.equal_lg}>
                            <Typography variant={TypographyFace.header} accent={Accent.light}>
                                КОНТАКТЫ
                            </Typography>
                            <br />
                            <h3 className="color-light">{data.title}</h3>
                        </Col>
                        <Col type={ColType.equal_lg}>
                            <List
                                items={data.offices.map((v, i) => ({
                                    label: (
                                        <ScrollLink to={`office_${i}`} smooth>
                                            <Typography
                                                variant={TypographyFace.body_m}
                                                accent={Accent.light}
                                                className="contacts__city_link"
                                            >
                                                {v.name}
                                            </Typography>
                                        </ScrollLink>
                                    ),
                                }))}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container>
                {data.offices.map((office, i) => {
                    return (
                        <Row key={office.name} id={`office_${i}`}>
                            <Col type={ColType.equal_lg}>
                                <h2 className="contacts__title">{office.name}</h2>
                                <Typography variant={TypographyFace.body_l} markdown>{office.address}</Typography>
                            </Col>
                            <Col type={ColType.equal_lg}>
                                <iframe
                                    src={office.embedded}
                                    className="contacts__iframe"
                                    height="320"
                                    width="600"
                                    frameBorder={0}
                                    style={{ border: 0 }}
                                    loading="lazy"
                                />
                            </Col>
                        </Row>
                    );
                })}
            </Container>
        </>
    );
};
