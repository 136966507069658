import React from 'react';
import { OrderFormInputs } from '@/pages/Order/types';
import cn from 'classnames';

export type OrderParamsProps = {
    data: OrderFormInputs;
    showChecking: boolean;
};

export const OrderParams: React.FC<OrderParamsProps> = ({ data, showChecking }) => {
    const {
        customer,
        contact,
        phone,
        email,
        name,
        amount,
        amountInPanel,
        amountOfLayers,
        ipcClass,
        thickness,
        material,
        sizeW,
        sizeH,
        panelSizeH,
        panelSizeW,
        copperOutsideThickness,
        copperInsideThickness,
        holes,
        finalCoat,
        solderMask,
        marking,
        solderMaskColor,
        markingColor,
        info,
    } = data;

    const _sizeH = parseFloat(sizeH?.replace(',', '.'));
    const _sizeW = parseFloat(sizeW?.replace(',', '.'));

    const _panelSizeH = parseFloat(panelSizeH?.replace(',', '.'));
    const _panelSizeW = parseFloat(panelSizeW?.replace(',', '.'));

    const amountOfPanels = Math.ceil(amount / amountInPanel);

    return (
        <div className="order_params">
            {!showChecking && (
                <div className="order_params__label">Изменить данные по Заказу можно в полях в формах ввода</div>
            )}

            <div className={cn('order_params__data', { "order_params__data--checking": showChecking })}>
                <div className={cn('order_params__grid', { "order_params__grid--checking": showChecking })}>
                    <div>Заказчик</div>
                    <div>{customer}</div>

                    <div>Контактное лицо</div>
                    <div>{contact}</div>

                    <div>Телефон</div>
                    <div>{phone}</div>

                    <div>Адрес электронной почты</div>
                    <div>{email}</div>

                    <div>Наименование платы</div>
                    <div>{name}</div>

                    <div>Количество плат</div>
                    <div>{amount}</div>

                    <div>Количество плат в панели</div>
                    <div>{amountInPanel}</div>

                    <div>Количество панелей</div>
                    <div>{Number.isFinite(amountOfPanels) ? amountOfPanels : ''}</div>

                    <div>Количество слоёв</div>
                    <div>{amountOfLayers}</div>

                    <div>Класс по IPC</div>
                    <div>{ipcClass}</div>

                    <div>Толщина платы, мм</div>
                    <div>{thickness}</div>

                    <div>Материал</div>
                    <div>{material}</div>

                    <div>Размер платы, мм</div>
                    <div>{_sizeH > 0 && _sizeW > 0 && _sizeW + 'x' + _sizeH}</div>

                    <div>Размер панели, мм</div>
                    <div>{_panelSizeH > 0 && _panelSizeW > 0 && _panelSizeW + 'x' + _panelSizeH}</div>

                    <div>Толщина меди на внешних слоях, мкм</div>
                    <div>{copperOutsideThickness}</div>

                    <div>Толщина меди на внутренних слоях, мкм</div>
                    <div>{copperInsideThickness}</div>

                    <div>Отверстия</div>
                    <div>{holes}</div>

                    <div>Финишное покрытие</div>
                    <div>{finalCoat}</div>

                    <div>Паяльная маска</div>
                    <div>
                        {solderMask} {solderMaskColor}
                    </div>

                    <div>Маркировка</div>
                    <div>
                        {marking} {markingColor}
                    </div>
                </div>
                <hr />
                <div className="order_params__info">
                    <div>Дополнительная информация</div>
                    <div>{info}</div>
                </div>
            </div>
        </div>
    );
};
