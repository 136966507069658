import React from 'react';
import './index.less';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { List, TopHeader } from '@/blocks';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType } from '@/types';
import { getImagePropsFromResponse } from '@/utils';
import { contractsWinding$ } from '@/models/contractsWindingPage';

export const Winding: React.FC = () => {
  const { data } = useStore(contractsWinding$.store);

  useLoadData(contractsWinding$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }
  return (
    <>
      <TopHeader title="ИЗГОТОВЛЕНИЕ МОТОЧНЫХ ИЗДЕЛИЙ" className="contracts-winding__header">
        <div className="contracts-winding__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.info}</Typography>
            <List items={data.products} />
          </Col>
          <Col type={ColType.equal_lg}>
            <div className="contracts-winding__img-container">
              {data.assets.data.map((asset) => (
                <img className="contracts-winding__img" key={asset.id} {...getImagePropsFromResponse(asset)} />
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
