import React from 'react';
import './index.less';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { TopHeader } from '@/blocks';
import { Col, Container, Row, Typography } from '@/layout';
import { contractsMechanic$ } from '@/models/contractsMechanicPage';
import { ColType } from '@/types';
import { getImagePropsFromResponse } from '@/utils';

export const Mechanic: React.FC = () => {
  const { data } = useStore(contractsMechanic$.store);

  useLoadData(contractsMechanic$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }
  return (
    <>
      <TopHeader title="МЕХАНИЧЕСКОЕ ПРОИЗВОДСТВО И ЛИТЬЁ ПЛАСТМАСС" className="contracts-mechanic__header">
        <div className="contracts-mechanic__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.mechanicInfo}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((v) => v.attributes.caption.includes('mechanic')) ?? null
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.plasticInfo}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            <div className="contracts-mechanic__img-container">
              <img
                {...getImagePropsFromResponse(
                  data.assets.data.find((v) => v.attributes.caption.includes('plastic-1')) ?? null
                )}
                className="contracts-mechanic__img"
              />
              <img
                {...getImagePropsFromResponse(
                  data.assets.data.find((v) => v.attributes.caption.includes('plastic-2')) ?? null
                )}
                className="contracts-mechanic__img"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
