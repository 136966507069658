import React from 'react';

type Props = {
  pcView: React.ReactElement;
  mobileView: React.ReactElement;
};

const checkIsMobileView = () => {
  return window.matchMedia('(max-width: 1200px)').matches;
};

export const MobileContainer: React.FC<Props> = ({ pcView, mobileView }) => {
  const [isMobile, setIsMobile] = React.useState(checkIsMobileView);

  React.useEffect(() => {
    const resizeListener = () => {
      if (checkIsMobileView()) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    window.addEventListener('resize', resizeListener);
    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return isMobile ? mobileView : pcView;
};
