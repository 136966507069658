import React from 'react';
import cn from 'classnames';
import { ListItem } from '@/types/blocks';
import { Typography } from '@/layout';
import { TypographyFace } from '@/types';
import './index.less';

type Props = {
  items: ListItem[];
  header?: string;
  className?: string;
  headerComponent?: React.ElementType<{ className: string }>,
};

export const List: React.FC<Props> = ({ items, header = '', className = '', headerComponent: HComponent  = 'h1' }) => {
  const classes = cn('list', { [className]: !!className });

  return (
    <div className={classes}>
      {!!header && <HComponent className="list__title">{header}</HComponent>}
      {items.map((v, i) => {
        return (
          <Typography className="list__item" variant={TypographyFace.body} key={i}>
            {v.label}
          </Typography>
        );
      })}
    </div>
  );
};
