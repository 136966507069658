// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/src/images/select-bg.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".select {\n  display: inline-block;\n}\n.select--block {\n  display: block;\n}\n.select__label {\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 22px;\n  margin-bottom: 8px;\n}\n.select__error {\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 15px;\n  color: #CC0000;\n  margin-top: 12px;\n}\n.select select {\n  padding: 11px 13px;\n  border: 1px solid #C9CAC8;\n  width: 100%;\n  height: 45px;\n  -moz-appearance: none;\n  -webkit-appearance: none;\n  appearance: none;\n  background: #fff url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center / cover;\n}\n.select--error select {\n  border-color: #CC0000;\n}\n.select ::placeholder {\n  color: #808080;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Select/index.less"],"names":[],"mappings":"AAEA;EAEE,qBAAA;AAFF;AAIE;EACE,cAAA;AAFJ;AAKE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;AAHJ;AAME;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAJJ;AAhBA;EAwBI,kBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,qBAAA;EACA,wBAAA;EACA,gBAAA;EACA,wFAAA;AALJ;AASE;EACE,qBAAA;AAPJ;AA7BA;EAwCI,cAAA;AARJ","sourcesContent":["@import \"../../less/colors\";\n\n.select {\n\n  display: inline-block;\n\n  &--block {\n    display: block;\n  }\n\n  &__label {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 22px;\n    margin-bottom: 8px;\n  }\n\n  &__error {\n    font-weight: 400;\n    font-size: 12px;\n    line-height: 15px;\n    color: @error;\n    margin-top: 12px;\n  }\n\n  select {\n    padding: 11px 13px;\n    border: 1px solid @gray_02;\n    width: 100%;\n    height: 45px;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    appearance: none;\n    background: #fff url(\"~/src/images/select-bg.svg\") no-repeat center center/cover;\n\n  }\n\n  &--error select {\n    border-color: @error;\n  }\n\n  ::placeholder {\n    color: @gray_00;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
