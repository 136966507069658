import { Asset } from '@/types/entities';
import { api, ENDPOINTS } from '@/api/api';
import { ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

const loadPlanar = async () => {
  const data = await api.get<ResponseDataType<{ content: string; assets: { data: ResponseType<Asset>[] } }>>(
    ENDPOINTS.pages.pcbPlanar
  );
  const res = getResponseData(data);
  return {
    content: res.content,
  };
};

const pcbPlanar$ = fetchDataFactory({ defaultData: null, loader: loadPlanar });

export { pcbPlanar$ };
