import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { Asset, ListItem, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsCablesPageData = {
  assets: { data: ResponseType<Asset>[] };
  possibilities: ListItem[];
  materials: ListItem[];
  dataToPlaceOrder: ListItem[];
};

const loadContractsCablesPage = async () => {
  const data = await api.get<ResponseDataType<ContractsCablesPageData>>(ENDPOINTS.pages.contractsCables, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsCables$ = fetchDataFactory({ defaultData: null, loader: loadContractsCablesPage });

export { contractsCables$ };
