import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import { ErrorPage, routes } from '@/pages';

import { ErrorBoundary } from '@/main/ErrorBoundary';
import { useStore } from 'effector-react';
import { loadError$ } from '@/models/loadError';
import '../less/global.less';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { Event } from '@/pages/News/Event';
import { Footer } from '@/blocks';
import { MenuRenderController } from '@/features/MainMenu/MenuRenderController';
import {ScrollToTop} from "@/utils";

export const App: React.FC = () => {
  const loadError = useStore(loadError$);

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <MenuRenderController />
        <ScrollToTop/>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={loadError ? <ErrorPage /> : route.element} />
          ))}
          <Route path="/news/:eventId" element={<Event />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <Footer />
      </ErrorBoundary>
    </BrowserRouter>
  );
};
