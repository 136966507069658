import React from 'react';
import { useParams } from 'react-router-dom';
import { useStore } from 'effector-react';
import { news$ } from '@/models/news';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import { getImagePropsFromResponse } from '@/utils';
import './index.less';

export const Event: React.FC = () => {
  const { eventId } = useParams();
  const { data } = useStore(news$.store);

  React.useEffect(() => {
    if (!data) {
      news$.methods.load();
    }
  }, [data]);

  const event = data.find((e) => e.id.toString() === eventId);

  return (
    <Container>
      {!event ? (
        <Row justify="center">
          <Col type={ColType.equal_lg}>
            <Typography variant={TypographyFace.header} accent={Accent.blue_02}>
              Кажется такой новости нет.
            </Typography>
            <br />
            <Typography variant={TypographyFace.header}>
              Пожалуйста, попробуйте перезагрузить страницу или обратитесь к администратору.
            </Typography>
          </Col>
        </Row>
      ) : (
        <>
          <Row className="event__container">
            <Typography variant={TypographyFace.header}>{event.title}</Typography>
          </Row>
          <Row>
            <Col type={ColType.content}>
              <Typography markdown>{event.content}</Typography>
            </Col>
            <Col type={ColType.side}>
              <img {...getImagePropsFromResponse(event.asset.data)} />
            </Col>
          </Row>
        </>
      )}
      {/*<Row>*/}
      {/*  <Col type={ColType.equal_lg}>*/}
      {/*    <Link to="/news">*/}
      {/*      <Typography variant={TypographyFace.body_m} accent={Accent.blue_03}>*/}
      {/*        Все новости*/}
      {/*      </Typography>*/}
      {/*    </Link>*/}
      {/*  </Col>*/}
      {/*</Row>*/}
    </Container>
  );
};
