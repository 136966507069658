import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Button, Link, Loader } from '@/components';
import { MainMenu } from '@/features';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import { Link as RouterLink } from 'react-router-dom';
import { List, OrderBlock } from '@/blocks';
import { contractsRoutes } from '@/pages/routes';
import { contracts$ } from '@/models/contractsPage';
import './index.less';
import OrderImg from '@/images/order-2.png';

export const Contracts: React.FC = () => {
  const { data } = useStore(contracts$.store);

  useLoadData(contracts$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <div className="contracts__top">
        <MainMenu />
        <Container>
          <Row>
            <Col type={ColType.equal_lg}>
              <Typography variant={TypographyFace.header} accent={Accent.light} className="contracts__top-header">
                КОНТРАКТНОЕ ПРОИЗВОДСТВО
              </Typography>
              <RouterLink to="/order" className="order-link">
                <Button accent={Accent.light}>ОФОРМИТЬ ЗАКАЗ</Button>
              </RouterLink>
            </Col>
            <Col type={ColType.equal_lg} className="contracts__type-links">
              <List
                items={contractsRoutes.map(({ label, path }) => {
                  return {
                    label: (
                      <Link theme={Accent.dark} to={path ?? '/'}>
                        {label}
                      </Link>
                    ),
                  };
                })}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <Typography markdown className="contracts__description">
            {data.info}
          </Typography>
        </Row>

        <OrderBlock img={OrderImg} />
      </Container>
    </>
  );
};
