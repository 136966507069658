import { Asset } from '@/types/entities';
import { api, ENDPOINTS } from '@/api/api';
import { ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

const loadHeatSink = async () => {
  const data = await api.get<ResponseDataType<{ content: string; assets: { data: ResponseType<Asset>[] } }>>(
    ENDPOINTS.pages.pcbHeatSink,
    {
      params: {populate: 'assets'},
    }
  );
  const res = getResponseData(data);
  return {
    content: res.content,
    pictures: res.assets.data,
  };
};

const pcbHeatSink$ = fetchDataFactory({defaultData: null, loader: loadHeatSink});

export { pcbHeatSink$ };
