import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Row } from '@/layout';
import { Button } from '@/components';
import { Accent } from '@/types';
import OrderImg from '@/images/order_1.png';
import './index.less';

type Props = {
  img?: string;
};

export const OrderBlock: React.FC<Props> = ({ img = OrderImg }) => {
  return (
    <Row className="order-block" align="center">
      <div className="order-block__container">
        <h1 className="order-block__first-header">РАСЧЁТ СРОКОВ ИЗГОТОВЛЕНИЯ И СТОИМОСТИ ВАШЕГО ЗАКАЗА</h1>
        <h1 className="order-block__second-header">В ТЕЧЕНИЕ 24 ЧАСОВ</h1>
        <RouterLink to="order" className="order-link">
          <Button accent={Accent.light}>РАССЧИТАТЬ ЗАКАЗ</Button>
        </RouterLink>
      </div>
      <img className="order-block__img" src={img} />
    </Row>
  );
};
