import React from 'react';
import { Link } from '@/components';
import { ViewIcon } from '@/icons';
import './index.less';

type Props = {
  path: string;
  label?: string;
};

export const DetailesLink: React.FC<Props> = ({ path, label = 'Подробно' }) => {
  return (
    <Link to={path} className="detailes-link">
      <span className="detailes-link__label">{label}</span>
      <ViewIcon className="detailes-link__icon" />
    </Link>
  );
};
