import React from 'react';
import { PcbCol } from '@/types/entities';
import { useStore } from 'effector-react';
import { pcbTechMobileRows } from '@/models/pcbTech';
import { PcbColNames } from '@/pages/PCBTech/constants';
import { IconButton } from '@/components';
import { Accent } from '@/types';
import { ArrowBottom, ArrowUp } from '@/icons';

export const PcbMobileTable: React.FC = () => {
  const data = useStore(pcbTechMobileRows);
  const [toggledRows, setToggledRows] = React.useState<boolean[]>([]);

  const handleRowToggle = (i: number) => {
    const currentState = toggledRows[i] ?? false;
    setToggledRows([...toggledRows.slice(0, i), !currentState, ...toggledRows.slice(i + 1)]);
  };

  return (
    <div style={{ width: '100%' }}>
      <h2 className="pcb-tech__table__mobile-header">Технологические возможности</h2>
      <table className="pcb-tech__table">
        {Object.keys(data).map((v, i) => {
          return (
            <React.Fragment key={i}>
              <thead>
                <tr className="pcb-tech__tr pcb-tech__tr__mobile-head">
                  <td colSpan={2}>{PcbColNames[v as PcbCol]}</td>
                  <IconButton
                    className="pcb-tech__mobile-row-toggle"
                    accent={Accent.light}
                    icon={!toggledRows[i] ? ArrowUp : ArrowBottom}
                    onClick={() => handleRowToggle(i)}
                  />
                </tr>
              </thead>
              {!toggledRows[i] && (
                <tbody>
                  {data[v as PcbCol].map(({ field, value }) => {
                    return (
                      <tr key={field} className="pcb-tech__tr">
                        <td>{field}</td>
                        <td>{value}</td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </React.Fragment>
          );
        })}
      </table>
    </div>
  );
};
