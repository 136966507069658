import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { ResponseDataType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsPageData = {
  info: string;
};

const loadContractsPage = async () => {
  const data = await api.get<ResponseDataType<ContractsPageData>>(ENDPOINTS.pages.contracts, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contracts$ = fetchDataFactory({ defaultData: null, loader: loadContractsPage });

export { contracts$ };
