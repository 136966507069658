import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { ResponseDataType } from '@/types';
import { getResponseData } from '@/utils';
import { AssetsArray } from '@/types/entities';
import { fetchDataFactory } from '@/models/fetchData';

type PcbPageData = {
  description: string;
  manufactoryInfo: string;
  manufactoryTypes: Array<{ title: string; description: string }>;
  assets: AssetsArray;
};

const loadPcbPage = async () => {
  const data = await api.get<ResponseDataType<PcbPageData>>(ENDPOINTS.pages.pcb, { params: DEFAULT_GET_PARAMS });
  return getResponseData(data);
};

const pcbPageData$ = fetchDataFactory({ defaultData: null, loader: loadPcbPage });
export { pcbPageData$ };
