import React from 'react';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import './index.less';

type Props = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export const TopHeader: React.FC<Props> = ({ title, children, className = '' }) => {
  return (
    <div className={`top-header__header ${className}`}>
      <Container className="top-header__header__container">
        <Row>
          <Col type={ColType.equal_lg} grow={false}>
            <Typography className="top-header__header__title" variant={TypographyFace.header} accent={Accent.light}>
              {title}
            </Typography>
          </Col>
        </Row>
      </Container>
      {children}
    </div>
  );
};
