import axios from 'axios';
import { setError } from '@/models/loadError';

export const api = axios.create({
    baseURL: process.env.API_BASE_URL,
    headers: { Authorization: `Bearer ${process.env.API_TOKKEN}` },
});

export const ENDPOINTS = {
    pages: {
        about: 'about',
        pcb: 'pcb',
        pcbTech: 'tech',
        pcbMaterials: 'materials',
        pcbLayered: 'layered',
        pcbHeatSink: 'heat-sink',
        pcbPlanar: 'planar',
        pcbSides: 'sides',
        pcbFlex: 'flex',
        pcbFrequency: 'frequency',
        contacts: 'contacts',
        contracts: 'contracts',
        contractsMount: 'mount',
        contractsProject: 'project',
        contractsDelivery: 'delivery',
        contractsCables: 'cables',
        contractsMechanic: 'mechanic',
        contractsWinding: 'winding',
        contractsProtection: 'protection',
        contractsScreensForSMT: 'screens-for-smt',
    },
    components: {
        events: 'event',
    },
    forms: {
        upload: 'upload',
        order: 'orders',
    },
};

export const DEFAULT_GET_PARAMS = {
    populate: '*',
};

api.interceptors.response.use(
    (res) => {
        return res;
    },
    (e) => {
        setError(e);
        return Promise.reject(e);
    }
);
