import React from 'react';
import {useStore} from 'effector-react';
import {Col, Container, Row, Typography} from '@/layout';
import {ColType, TypographyFace} from '@/types';
import {chunkArray, getImagePropsFromResponse} from '@/utils';
import './layered.less';
import {PcbTypesBlock} from '@/blocks/PcbTypesBlock/PcbTypesBlock';
import {useLoadData} from '@/hooks/useLoadData';
import {Loader} from '@/components';
import {pcbSides$} from "@/models/pcbSides";

export const PcbSides: React.FC = () => {
    const {data} = useStore(pcbSides$.store);

    useLoadData(pcbSides$.methods);

    if (!data) {
        return <Loader fullWidth/>;
    }

    return (
            <Container>
                <Row>
                    <Col type={ColType.equal_lg}>
                        <Typography variant={TypographyFace.header}>
                            ОДНОСТОРОННИЕ И&nbsp;ДВУСТОРОННИЕ ПЕЧАТНЫЕ ПЛАТЫ
                        </Typography>
                    </Col>
                </Row>
                <Row wrap>
                    <Col type={ColType.equal_lg}>
                        <Typography markdown>{data.content}</Typography>
                    </Col>
                    <Col type={ColType.equal_lg}>
                        {chunkArray(data.pictures, 2).map((v, index) => {
                            return (
                                    <div key={index} className="pcb-layered__img-container">
                                        {v.map((i) => {
                                            return <img key={i.id} {...getImagePropsFromResponse(i)} />;
                                        })}
                                    </div>
                            );
                        })}
                    </Col>
                </Row>
                <PcbTypesBlock type="types"/>
            </Container>
    );
};
