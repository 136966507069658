import { PcbRow } from '@/types/entities';
import { createEffect, createStore } from 'effector';
import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { ResponseDataType } from '@/types';
import { getResponseData } from '@/utils';
import { convertRowsToMobileView } from '@/pages/PCBTech/utils';

type PcbRows = PcbRow[];

const pcbTechState = createStore<PcbRows>([]);
const pcbTechMobileRows = pcbTechState.map((s) => convertRowsToMobileView(s));

const loadPcbTech = createEffect(async () => {
  const data = await api.get<ResponseDataType<{ rows: PcbRows }>>(ENDPOINTS.pages.pcbTech, {
    params: DEFAULT_GET_PARAMS,
  });
  const res = getResponseData(data);
  return res.rows;
});

pcbTechState.on(loadPcbTech.doneData, (_, v) => v);

export { pcbTechState, loadPcbTech, pcbTechMobileRows };
