import React, { useState } from 'react';
import { Col, Container, Row, Typography } from '@/layout';
import './index.less';
import { Accent, ColType, ResponseDataType, TypographyFace } from '@/types';
import { Button, Link } from '@/components';
import { api, ENDPOINTS } from '@/api/api';
import { AboutPageData } from '@/models/aboutPage';
import { MainMenu } from '@/features';
import { CircleProgressIcon } from '@/icons';
import { FileList } from '@/pages/Order/FileList';

export const Order: React.FC = () => {
    const [formFiles, setFormFiles] = useState<File[]>([]);
    const [formFileSent, setFormFileSent] = useState(false);
    const [isUploading, setIsUploading] = useState(false);

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (!files?.length) {
            return;
        }

        setFormFiles([...formFiles, ...files]);
    };

    const removeFile = (i: number) => {
        if (formFiles.length > i) {
            const newArr = [...formFiles];
            newArr.splice(i, 1);
            setFormFiles(newArr);
        }
    };

    const sendForm = async () => {
        if (formFiles.length === 0) {
            return;
        }

        setIsUploading(true);
        const uploadedFileIds = [];

        for (const file of formFiles) {
            const formData = new FormData();
            formData.append('files', file);
            const uploadResponse = await api.post(ENDPOINTS.forms.upload, formData);
            uploadedFileIds.push(uploadResponse.data[0].id);
        }


        if (uploadedFileIds.length > 0) {
            await api.post<ResponseDataType<AboutPageData>>(ENDPOINTS.forms.order, {
                data: { file: uploadedFileIds, customer: "Только файлы" },
            });

            setFormFileSent(true);
        }
    };

    return (
        <>
            <div className="order__root">
                <MainMenu />
                <Container>
                    <Row>
                        <Col type={ColType.equal_lg}>
                            <Typography variant={TypographyFace.header} accent={Accent.light}>
                                ОФОРМЛЕНИЕ
                                <br />
                                ЗАКАЗА
                            </Typography>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Container className="order__header_container">
                <Row wrap>
                    <Col type={ColType.equal_lg}>
                        {formFileSent && (
                            <Typography className="order__sent_text2">
                                Ваш заказ отправлен, наш менеджер свяжется с вами.
                            </Typography>
                        )}
                        <div className="order__header_buttons ">
                            {!formFileSent && (
                                <>
                                    <a href="\order_form.xls" target="_blank">
                                        <Button accent={Accent.dark}>СКАЧАТЬ БЛАНК ЗАКАЗА</Button>
                                    </a>
                                    <FileList formFiles={formFiles} removeFile={removeFile} />
                                    <div className="order__file_input_wrapper">
                                        <input type="file" id="order_file_input" multiple onChange={onInputChange} />
                                        <Button accent={Accent.dark} type="button">
                                            <label htmlFor="order_file_input">
                                                {!formFiles.length ? 'ЗАГРУЗИТЬ ФАЙЛ' : 'ЗАГРУЗИТЬ ЕЩЕ ФАЙЛ'}
                                            </label>
                                        </Button>
                                    </div>
                                    <Button
                                        accent={Accent.dark}
                                        onClick={sendForm}
                                        isDisabled={!formFiles.length || isUploading}
                                    >
                                        {isUploading && <CircleProgressIcon className="order__loading" />}
                                        {!isUploading && 'ОТПРАВИТЬ'}
                                    </Button>
                                </>
                            )}
                            {formFileSent && <h3 className="color-light">Ваш заказ отправлен</h3>}
                        </div>
                    </Col>
                    <Col className="order__right_text_wrapper">
                        <Typography variant={TypographyFace.body_l} className="order__right_text">
                            Вы можете скачать бланк заказа, заполнить его и загрузить, воспользовавшись формой справа,
                            или прислать нам на электронную почту: <br />
                            <a href="mailto:pcb@aviv-group.ru">pcb@aviv-group.ru</a>.
                            <br />
                            <br />
                            Либо заполните онлайн форму «Создание заказа» на данной странице. После этого наш менеджер
                            свяжется с вам.
                        </Typography>
                    </Col>
                </Row>
                <Row>
                    <Col type={ColType.equal_lg} className="order__bottom_left_col">
                        <h1 className="order__creat_order_title">СОЗДАНИЕ ЗАКАЗА ОНЛАЙН</h1>

                        <Link to="/order/form" className="order__header_buttons ">
                            <Button accent={Accent.dark} onClick={sendForm}>
                                ЗАПОЛНИТЬ ФОРМУ ОНЛАЙН
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
