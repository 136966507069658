import React from 'react';

import { Col, Typography } from '@/layout';
import './index.less';
import { foundationYear } from '@/constants';
import { Accent, ColType } from '@/types';

export const FoundationYearsLogo: React.FC = () => {
  const yearsAfterFoundation = new Date().getFullYear() - foundationYear;

  return (
    <Col className="accent-with-logo" type={ColType.side}>
      <div className="accent-with-logo__header">{yearsAfterFoundation}</div>
      <Typography accent={Accent.blue_03}>лет на рынке электроники</Typography>
    </Col>
  );
};
