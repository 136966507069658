import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { Asset, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsMechanicPageData = {
  assets: { data: ResponseType<Asset>[] };
  mechanicInfo: string;
  plasticInfo: string;
};

const loadContractsMechanicPage = async () => {
  const data = await api.get<ResponseDataType<ContractsMechanicPageData>>(ENDPOINTS.pages.contractsMechanic, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsMechanic$ = fetchDataFactory({ defaultData: null, loader: loadContractsMechanicPage });

export { contractsMechanic$ };
