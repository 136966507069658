import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { ListItem, ResponseDataType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsProjectPageData = {
  mainInfo: string;
  secondaryInfo: string;
  features: ListItem[];
};

const loadContractsProjectPage = async () => {
  const data = await api.get<ResponseDataType<ContractsProjectPageData>>(ENDPOINTS.pages.contractsProject, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsProject$ = fetchDataFactory({ defaultData: null, loader: loadContractsProjectPage });

export { contractsProject$ };
