import React from 'react';
import { useLocation } from 'react-router-dom';

import { Col, Row } from '@/layout';
import { Accent, ColType } from '@/types';
import { List } from '@/blocks';
import { pcbTypeRoutes } from '@/pages/routes';
import { Button, DetailesLink, Link } from '@/components';
import './index.less';

type Props = {
  type: 'tech' | 'materials' | 'types';
};

export const PcbTypesBlock: React.FC<Props> = ({ type }) => {
  const location = useLocation();
  const listItems = type !== 'types' ? pcbTypeRoutes : pcbTypeRoutes.filter((r) => r.path !== location.pathname);

  return (
    <Row wrap className="pcb-type-block">
      <Col type={ColType.equal_lg}>
        <h2>Типы печатных плат</h2>
        <List
          items={listItems.map((route) => ({
            label: (
              <div className="pcb-type-block__container">
                <span>{route.label}</span>
                <DetailesLink path={route.path ?? '/'} />
              </div>
            ),
          }))}
        />
      </Col>
      <Col type={ColType.equal_lg} className="pcb-type-block__right_col">
        {(type === 'types' || type === 'materials') && (
          <Button className="pcb-type-block__tech-button" accent={Accent.light}>
            <Link to="/pcb/tech" theme={Accent.dark}>
              ТЕХНОЛОГИЧЕСКИЕ ВОЗМОЖНОСТИ ПРОИЗВОДСТВ
            </Link>
          </Button>
        )}
        {(type === 'types' || type === 'tech') && (
          <Button accent={Accent.light}>
            <Link to="/pcb/materials" theme={Accent.dark}>
              ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ
            </Link>
          </Button>
        )}
      </Col>
    </Row>
  );
};
