import React from 'react';
import './index.less';
import { MainMenu } from '@/features';
import { Typography } from '@/layout';
import { Accent, TypographyFace } from '@/types';

export const Footer: React.FC = () => {
  return (
    <div className="footer-block">
      <MainMenu />
      <Typography className="footer-block__copyright" variant={TypographyFace.body_s} accent={Accent.light}>
        © «АВИВ Групп», 2021. Все права защищены.
      </Typography>
    </div>
  );
};
