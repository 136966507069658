import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { ResponseDataType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsProtectionPageData = {
  info: string;
};

const loadContractsProtectionPage = async () => {
  const data = await api.get<ResponseDataType<ContractsProtectionPageData>>(ENDPOINTS.pages.contractsProtection, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsProtection$ = fetchDataFactory({ defaultData: null, loader: loadContractsProtectionPage });

export { contractsProtection$ };
