import { Asset } from '@/types/entities';
import { api, ENDPOINTS } from '@/api/api';
import { ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

const loadPcbFrequency = async () => {
  const data = await api.get<ResponseDataType<{ content: string; assets: { data: ResponseType<Asset>[] } }>>(
    ENDPOINTS.pages.pcbFrequency,
    {
      params: {populate: 'assets'},
    }
  );
  const res = getResponseData(data);


  return {
    content: res.content,
    pictures: res.assets.data,
  };
};

const pcbFrequency$ = fetchDataFactory({defaultData: null, loader: loadPcbFrequency});

export { pcbFrequency$ };
