import { ResponseDataType, ResponseType } from '@/types/common';

export type Asset = {
  name: string;
  alternativeText: string;
  caption: string;
  width: number;
  height: number;
  formats: {
    thumbnail: {
      name: string;
      hash: string;
      ext: string;
      mime: string;
      path: null | string;
      width: number;
      height: number;
      size: number;
      url: string;
    };
  };
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null | string;
  provider: string;
  provider_metadata: null | string;
  createdAt: string;
  updatedAt: string;
};

export type NewsEvent = {
  id: string;
  title: string;
  date: string;
  content: string;
  asset: ResponseDataType<Asset>;
};

export type AssetsArray = { data: Array<ResponseType<Asset>> };

export enum PcbCol {
  standart = 'standart',
  express = 'express',
  proto = 'proto',
}

export type PcbRow = {
  field: string;
  [PcbCol.standart]: string;
  [PcbCol.express]: string;
  [PcbCol.proto]: string;
};

export type ListItem = {
  id: number;
  label: string;
};
