import React from 'react';
import { Typography } from '@/layout';
import { TypographyFace } from '@/types';
import './index.less';

type Props = {
    header: string;
    info: string;
};
export const H2WithInfoBlock: React.FC<Props> = ({ header, info }) => {
    return (
        <div className="h2-with-info">
            <h2><span>{header}</span></h2>
            <Typography>{info}</Typography>
        </div>
    );
};
