import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { Asset, ListItem, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsDeliveryPageData = {
  info: string;
  possibilities: ListItem[];
  assets: { data: ResponseType<Asset>[] };
};

const loadContractsDeliveryPage = async () => {
  const data = await api.get<ResponseDataType<ContractsDeliveryPageData>>(ENDPOINTS.pages.contractsDelivery, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsDelivery$ = fetchDataFactory({ defaultData: null, loader: loadContractsDeliveryPage });

export { contractsDelivery$ };
