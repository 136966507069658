import React from 'react';
import { useStore } from 'effector-react';
import { pcbMaterials$ } from '@/models/pcbMaterials';
import { List, TopHeader } from '@/blocks';
import './index.less';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType, TypographyFace } from '@/types';
import { PcbTypesBlock } from '@/blocks/PcbTypesBlock/PcbTypesBlock';
import { useLoadData } from '@/hooks/useLoadData';

export const PcbMaterials: React.FC = () => {
  const { data } = useStore(pcbMaterials$.store);
  useLoadData(pcbMaterials$.methods);

  return (
    <>
      <TopHeader title="ИСПОЛЬЗУЕМЫЕ МАТЕРИАЛЫ">
        <div className="pcb-materials__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <Col type={ColType.equal_lg}>
            <List
              className="pcb-materials__list"
              items={data.map(({ group, files }) => {
                return {
                  label: (
                    <div className="pcb-materials__list-item">
                      <h2>{group}</h2>
                      <span className="pcb-materials__list-files-container">
                        {files.map((f) => (
                          <a className="pcb-materials__list-file" key={f.name} target="_blank" href={`${process.env.BASE_URL}${f.url}`} rel="noreferrer">
                            <Typography variant={TypographyFace.body_l}>{f.caption}</Typography>
                            <Typography variant={TypographyFace.body_s} className="pcb-materials__list-file-tooltip">
                              Скачать информацию о материале в формате PDF
                            </Typography>
                          </a>
                        ))}
                      </span>
                    </div>
                  ),
                };
              })}
            />
          </Col>
        </Row>

        <PcbTypesBlock type="materials" />
      </Container>
    </>
  );
};
