import React from 'react';
import { PcbRow } from '@/types/entities';

type Props = {
  rows: PcbRow[];
};

export const PcbDesktopTable: React.FC<Props> = ({ rows }) => {
  return (
    <table className="pcb-tech__table">
      <tr className="pcb-tech__tr">
        <th align="left">Технологические возможности</th>
        <th align="left">Стандарт</th>
        <th align="left">Топ (прототипы)</th>
        <th align="left">Срочное производство</th>
      </tr>
      {rows.map(({ field, standart, proto, express }, i) => {
        return (
          <tr key={i} className="pcb-tech__tr">
            <td>{field}</td>
            <td>{standart}</td>
            <td>{proto}</td>
            <td>{express}</td>
          </tr>
        );
      })}
    </table>
  );
};
