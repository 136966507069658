import React from 'react';

import './index.less';
import { PCMenu } from '@/features/MainMenu/components/PCMenu';
import { MobileMenu } from '@/features/MainMenu/components/MobileMenu';
import { MobileContainer } from '@/layout';

export const MainMenu: React.FC = () => {
  return <MobileContainer pcView={<PCMenu />} mobileView={<MobileMenu />} />;
};
