// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/slick-carousel/slick/slick.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/slick-carousel/slick/slick-theme.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".carousel__top {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px 0;\n}\n.carousel__buttons {\n  display: flex;\n}\n.carousel__button path {\n  fill: #fff;\n}\n.carousel__slider {\n  display: inline-block;\n}\n.carousel__slides {\n  margin: 0 -15px;\n}\n", "",{"version":3,"sources":["webpack://./src/blocks/Carousel/index.less"],"names":[],"mappings":"AAKE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EAEI,UAAA;AALN;AASE;EACE,qBAAA;AAPJ;AAUE;EACE,eAAA;AARJ","sourcesContent":["@import \"~slick-carousel/slick/slick.css\";\n@import \"~slick-carousel/slick/slick-theme.css\";\n@import \"src/less/colors\";\n\n.carousel {\n  &__top {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 15px 0;\n  }\n\n  &__buttons {\n    display: flex;\n  }\n\n  &__button {\n    path {\n      fill: @white;\n    }\n  }\n\n  &__slider {\n    display: inline-block;\n  }\n\n  &__slides {\n    margin: 0 -15px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
