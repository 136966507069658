import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import './index.less';
import { ColType } from '@/types/common';

type Props = {
  className?: string;
  type?: ColType;
  grow?: boolean;
};
type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Col: React.FC<PropsWithChildren<Props & DivProps>> = ({
  children,
  className = '',
  type = ColType.equal_lg,
  grow = true,
  ...nativeProps
}) => {
  const classes = cn('col', {
    [className]: !!className,
    [`col__${type}`]: true,
    [`col-grow-${grow}`]: true,
  });
  return (
    <div {...nativeProps} className={classes}>
      {children}
    </div>
  );
};
