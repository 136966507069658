import React, { useRef } from 'react';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import { loadError$, resetError } from '@/models/loadError';
import { useStore } from 'effector-react';
import { useLocation } from 'react-router-dom';

export const ErrorPage: React.FC = () => {
  const loadError = useStore(loadError$);
  const location = useLocation();
  const ref = useRef(location.pathname);

  React.useEffect(() => {
    if (loadError && ref.current !== location.pathname) {
      resetError();
    }
  }, [location.pathname, loadError]);

  return (
    <Container>
      <Row justify="center">
        <Col type={ColType.equal_lg}>
          <Typography variant={TypographyFace.header} accent={Accent.blue_02}>
            Что-то пошло не так.
          </Typography>
          <br />
          <Typography variant={TypographyFace.header}>
            Пожалуйста, попробуйте перезагрузить страницу или обратитесь к администратору.
          </Typography>
        </Col>
      </Row>
    </Container>
  );
};
