import React from 'react';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import { MainMenu } from '@/features';

export const NotFoundPage: React.FC = () => {
  return (
    <Container>
      <Row justify="center">
        <Col type={ColType.equal_lg}>
          <Typography variant={TypographyFace.header} accent={Accent.blue_02}>
            Данной страницы не существует.
          </Typography>
          <br />
          <Typography variant={TypographyFace.header}>Пожалуйста обратитесь к администратору.</Typography>
        </Col>
      </Row>
    </Container>
  );
};
