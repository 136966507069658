import { PcbCol, PcbRow } from '@/types/entities';

export const convertRowsToMobileView = (rows: PcbRow[]) => {
  return rows.reduce((acc, { field, standart, proto, express }) => {
    return {
      ...acc,
      [PcbCol.standart]: [...(acc?.[PcbCol.standart] ?? []), { field, value: standart }],
      [PcbCol.proto]: [...(acc?.[PcbCol.proto] ?? []), { field, value: proto }],
      [PcbCol.express]: [...(acc?.[PcbCol.express] ?? []), { field, value: express }],
    };
  }, {} as Record<PcbCol, Array<{ field: string; value: string }>>);
};
