import React, { PropsWithChildren } from 'react';
import ReactMarkdown from 'react-markdown';
import cn from 'classnames';

import { Accent, TypographyFace } from '@/types/common';
import './index.less';

type Props = {
    className?: string;
    variant?: TypographyFace;
    accent?: Accent;
    markdown?: boolean;
    markdownDisallowedElements?: string[];
};

type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Typography: React.FC<PropsWithChildren<Props & DivProps>> = ({
    children,
    className = '',
    variant = TypographyFace.body,
    accent = Accent.dark,
    markdown = false,
    markdownDisallowedElements,
    ...nativeProps
}) => {
    const classes = cn('typography', {
        [className]: !!className,
        [`typography__${variant}`]: true,
        [`typography-${accent}`]: true,
    });

    return (
        <div {...nativeProps} className={classes}>
            {markdown && typeof children === 'string' ? (
                <ReactMarkdown unwrapDisallowed disallowedElements={markdownDisallowedElements}>
                    {children}
                </ReactMarkdown>
            ) : (
                children
            )}
        </div>
    );
};
