import React, { PropsWithChildren } from 'react';
import cn from 'classnames';

import './index.less';

type Props = {
  className?: string;
};
type DivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Container: React.FC<PropsWithChildren<Props & DivProps>> = ({
  children,
  className = '',
  ...nativeProps
}) => {
  const classes = cn('container', {
    [className]: !!className,
  });
  return (
    <div {...nativeProps} className={classes}>
      {children}
    </div>
  );
};
