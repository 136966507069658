import React from 'react';
import { MainMenu } from '@/features';
import './index.less';
import { Col, Container, Row, Typography } from '@/layout';
import { Accent, ColType, TypographyFace } from '@/types';
import { Button, Link, Loader } from '@/components';
import { Link as RouterLink } from 'react-router-dom';
import { List, OrderBlock } from '@/blocks';
import { pcbTypeRoutes } from '@/pages/routes';
import { pcbPageData$ } from '@/models/pcbPage';
import { useStore } from 'effector-react';
import { getImagePropsFromResponse } from '@/utils';
import OrderImg from '@/images/order-2.png';
import { useLoadData } from '@/hooks/useLoadData';

export const Pcb: React.FC = () => {
  const { data } = useStore(pcbPageData$.store);
  useLoadData(pcbPageData$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <div className="pcb__top">
        <MainMenu />
        <Container>
          <Row>
            <Col type={ColType.equal_lg}>
              <Typography variant={TypographyFace.header} accent={Accent.light} className="pcb__top-header">
                ПЕЧАТНЫЕ ПЛАТЫ
              </Typography>
              <RouterLink to="/order" className="order-link">
                <Button accent={Accent.light}>ОФОРМИТЬ ЗАКАЗ</Button>
              </RouterLink>
            </Col>
            <Col type={ColType.equal_lg} className="pcb__type-links">
              <List
                items={pcbTypeRoutes.map(({ label, path }) => {
                  return {
                    label: (
                      <Link theme={Accent.dark} to={path ?? '/'} className="pcb__type-link">
                        {label}
                      </Link>
                    ),
                  };
                })}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row align="start" className="pcb__description">
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.description}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((img) => img.attributes.caption === 'description') ?? null
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <h1>ОСНОВНЫЕ ТИПЫ ПРОИЗВОДСТВ</h1>
          </Col>
        </Row>
        <Row className="pcb__factory-type__container">
          {data.manufactoryTypes.map(({ description, title }, i) => {
            return (
              <Col type={ColType.equal_md} key={i} className="pcb__factory-type">
                <h2 className="pcb__factory-type__title">{title}</h2>
                <Typography variant={TypographyFace.body_m} accent={Accent.light}>
                  {description}
                </Typography>
              </Col>
            );
          })}
        </Row>

        <Row className="pcb__info">
              <Col type={ColType.small_side} className="pcb__info-images">
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((img) => img.attributes.caption === 'list_first') ?? null
              )}
              className="pcb__list-img"
            />
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((img) => img.attributes.caption === 'list_second') ?? null
              )}
              className="pcb__list-img"
            />
          </Col>
          <Col type={ColType.content}>
            <List items={data.manufactoryInfo.split('\n').map((v) => ({ label: v }))} />
          </Col>
        </Row>

        <OrderBlock img={OrderImg} />
      </Container>
    </>
  );
};
