import { Asset } from '@/types/entities';
import { api, ENDPOINTS } from '@/api/api';
import { ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

const loadContractsScreensForSMT = async () => {
  const data = await api.get<
    ResponseDataType<{
      content: string;
      assets: { data: ResponseType<Asset>[] };
      params: { id: string; label: string }[];
      right_side: string;
    }>
  >(ENDPOINTS.pages.contractsScreensForSMT, {
    params: { populate: ['assets', 'params'] },
  });
  const res = getResponseData(data);

  return {
    content: res.content,
    pictures: res.assets.data,
    params: res.params,
    right_side: res.right_side,
  };
};

const contractsScreensForSMT$ = fetchDataFactory({ defaultData: null, loader: loadContractsScreensForSMT });

export { contractsScreensForSMT$ };
