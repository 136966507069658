import React from 'react';
import { useLocation } from 'react-router-dom';
import { transparentMenuPages } from '@/constants';
import { MainMenu } from '@/features';

export const MenuRenderController: React.FC = () => {
  const location = useLocation();

  if (transparentMenuPages.includes(location.pathname)) {
    return null;
  }

  return (
    <div className="top-header__container">
      <MainMenu />
    </div>
  );
};
