import { Asset, ResponseDataType, ResponseType } from '@/types';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export function chunkArray<T>(arr: T[], size: number): T[][] {
  return arr.length > size ? [arr.slice(0, size), ...chunkArray(arr.slice(size), size)] : [arr];
}

export function getResponseData<T>(res: AxiosResponse<ResponseDataType<T>>): T {
  return res.data.data.attributes;
}

export function getImagePropsFromResponse<T extends ResponseType<Asset>>(asset: T | null) {
  if (!asset) {
    return { alt: '', src: '' };
  }
  return {
    alt: `${asset.attributes.caption ?? ''}`,
    src: `${process.env.BASE_URL}${asset.attributes.url}`,
  };
}

export function take<T>(arr: Array<T>, count: number): Array<T> {
  return arr.slice(0, count);
}

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
