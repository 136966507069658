import { NewsEvent, ResponseDataType } from '@/types';
import { api, ENDPOINTS } from '@/api/api';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

const loadNews = async () => {
  const data = await api.get<ResponseDataType<{ event: NewsEvent[] }>>(ENDPOINTS.components.events, {
    params: { populate: [`${ENDPOINTS.components.events}.asset`] },
  });
  return getResponseData(data).event.sort((event1, event2) => {
    return new Date(event2.date).getTime() - new Date(event1.date).getTime();
  });
};

const news$ = fetchDataFactory({ defaultData: [], loader: loadNews });
export { news$ };
