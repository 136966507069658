import React, {PropsWithChildren, FC} from 'react';
import cn from 'classnames';

import './index.less';
import {BaseButtonProps} from './types';

export const Button: FC<PropsWithChildren<BaseButtonProps>> = ({
                                                                   accent,
                                                                   onClick,
                                                                   onHover,
                                                                   children,
                                                                   isDisabled = false,
                                                                   className = '',
                                                                   type,
                                                               }) => {

    const classes = cn('button', {[`button-${accent}`]: true, [className]: !!className});

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        !isDisabled && !!onClick && onClick(e);
    };

    const handleHover = (isShown: boolean) => (e: React.MouseEvent<HTMLButtonElement>) => {
        !isDisabled && !!onHover && onHover(e, isShown);
    };

    return (
            <button
                    disabled={isDisabled}
                    className={classes}
                    onClick={handleClick}
                    onMouseOver={handleHover(true)}
                    onMouseLeave={handleHover(false)}
                    type={type}
            >
                {children}
            </button>
    );
};
