import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { contractsMount$ } from '@/models/contractsMountPage';
import { List, TopHeader } from '@/blocks';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType } from '@/types';
import { getImagePropsFromResponse } from '@/utils';
import './index.less';

export const Mount: React.FC = () => {
  const { data } = useStore(contractsMount$.store);

  useLoadData(contractsMount$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <TopHeader title="МОНТАЖ ПЕЧАТНЫХ ПЛАТ">
        <div className="contracts-mount__top-image" />
      </TopHeader>

      <Container>
        <Row>
          {data.mainInfo.split('\n').map((block, i) => (
            <Col type={ColType.equal_lg} key={i}>
              <Typography markdown>{block}</Typography>{' '}
            </Col>
          ))}
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.automaticInfo}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((v) => v.attributes.caption.includes('automatic-1')) ?? null
              )}
              className="contracts-mount__img"
            />
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((v) => v.attributes.caption.includes('automatic-2')) ?? null
              )}
            />
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((v) => v.attributes.caption.includes('automatic-3')) ?? null
              )}
            />
          </Col>
          <Col type={ColType.equal_lg}>
            <List items={data.automaticCapabilities} header="Технические возможности" headerComponent="h2" />
          </Col>
        </Row>

        <Row>
          <h1>РУЧНОЙ МОНТАЖ</h1>
        </Row>

        <Row>
          <img
            {...getImagePropsFromResponse(
              data.assets.data.find((v) => v.attributes.caption.includes('manual')) ?? null
            )}
          />
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.manualInfo}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            <List items={data.manualCapabilities} header="Технические возможности" headerComponent="h2"/>
          </Col>
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.washingInfo}</Typography>
          </Col>
          <Col type={ColType.equal_lg}>
            {' '}
            <img
              {...getImagePropsFromResponse(
                data.assets.data.find((v) => v.attributes.caption.includes('washing')) ?? null
              )}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};
