import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { Asset, ListItem, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsWindingPageData = {
  assets: { data: ResponseType<Asset>[] };
  info: string;
  products: ListItem[];
};

const loadContractsWindingPage = async () => {
  const data = await api.get<ResponseDataType<ContractsWindingPageData>>(ENDPOINTS.pages.contractsWinding, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsWinding$ = fetchDataFactory({ defaultData: null, loader: loadContractsWindingPage });

export { contractsWinding$ };
