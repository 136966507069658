import React, { PropsWithChildren } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import cn from 'classnames';

import './index.less';
import { Accent } from '@/types';

type Props = {
  to: string;
  theme?: Accent;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const Link: React.FC<PropsWithChildren<Props>> = ({ to, className = '', theme = Accent.light, children,onClick }) => {
  return (
    <RouterLink to={to} className={cn('main-link', { [className]: !!className, [`main-link-${theme}`]: true })} onClick={onClick}>
      {children}
    </RouterLink>
  );
};
