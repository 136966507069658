import { combine, createEffect, createEvent, createStore, restore } from 'effector';

export function fetchDataFactory<D>({ defaultData, loader }: { defaultData: D; loader: () => Promise<D> }) {
  const data$ = createStore(defaultData);
  const loadFx$ = createEffect(loader);
  const resetEv$ = createEvent();

  data$.on(loadFx$.doneData, (_, p) => p as D);
  data$.reset(resetEv$);
  const $fetchError = restore<Error>(loadFx$.failData, null);
  $fetchError.reset(resetEv$);

  return {
    store: combine({ loading: loadFx$.pending, error: $fetchError, data: data$ }),
    methods: { reset: resetEv$, load: loadFx$ },
  };
}
