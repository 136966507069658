import { CircleCloseIcon } from '@/icons';
import React from 'react';

type Props = {
    formFiles: File[];
    removeFile?: (i: number) => void;
};
export const FileList: React.FC<Props> = ({ formFiles, removeFile }) => {
    if (formFiles.length === 0) {
        return null;
    }
    return (
        <div>
            {formFiles.map((file, i) => (
                <div className="order__file_info" key={i}>
                    {file.name}
                    {!!removeFile && (
                        <div onClick={() => removeFile(i)}>
                            Удалить
                            <CircleCloseIcon />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};
