import { Col, Row } from '@/layout';
import { TextInput } from '@/components/TextInput/TextInput';
import { ColType } from '@/types';
import { Select } from '@/components/Select/Select';
import React from 'react';
import { FormState, UseFormRegister } from 'react-hook-form/dist/types/form';
import { OrderFormInputs } from '@/pages/Order/types';

export type OrderFormContentProps = {
    register: UseFormRegister<OrderFormInputs>;
    formState: FormState<OrderFormInputs>;
    data: OrderFormInputs;
};
export const OrderFormContent: React.FC<OrderFormContentProps> = ({ register, formState: { errors }, data }) => {
    const amountOfPanels = Math.ceil(data.amount / data.amountInPanel);

    return (
        <>
            <Col className="order__col_50">
                <TextInput
                    block
                    className="order__input"
                    label="Наименование заказчика"
                    placeholder="Название компании"
                    errors={errors}
                    {...register('customer')}
                />

                <TextInput
                    block
                    className="order__input"
                    label="Контактное лицо"
                    placeholder="ФИО, должность"
                    errors={errors}
                    {...register('contact')}
                />

                <TextInput
                    block
                    className="order__input"
                    label="Наименование платы"
                    placeholder="Введите наименование платы"
                    errors={errors}
                    {...register('name', { required: 'Введите наименование платы' })}
                />
            </Col>
            <Col className="order__col_50">
                <TextInput
                    block
                    className="order__input"
                    label="Количество плат"
                    placeholder="Введите нужное количество"
                    errors={errors}
                    {...register('amount', {
                        required: 'Введите нужное количество.',
                    })}
                />
                <TextInput
                    block
                    className="order__input"
                    label="Ваш телефон"
                    placeholder="+ 7 (___) ___-__-__"
                    inputMaskProps={{ mask: '+ 7 (999) 999-99-99' }}
                    errors={errors}
                    {...register('phone', {
                        required: 'Введите ваш номер телефона для обратной связи.',
                        pattern: {
                            value: /\+\s7\s\(\d{3}\)\s\d{3}-\d{2}-\d{2}/,
                            message: 'Введите ваш номер телефона для обратной связи.',
                        },
                    })}
                />
                <TextInput
                    block
                    className="order__input"
                    label="Ваш e-mail"
                    placeholder="Адрес электронной почты"
                    errors={errors}
                    {...register('email', {
                        required: 'Введите адрес электронной почты.',
                        pattern: {
                            value: /.*@.*/,
                            message: 'Введите правильно отформатированный адрес электронной почты.',
                        },
                    })}
                />
            </Col>
            <Col className="order__col_50">
                <div className="order__title">ТЕХНИЧЕСКИЕ ДАННЫЕ</div>
                <Row wrap className="order__small_row">
                    <Col type={ColType.equal_sm} className="order__col_50_2">
                        <TextInput
                            block
                            className="order__input"
                            label="Количество плат в панели"
                            placeholder="Введите нужное количество"
                            errors={errors}
                            {...register('amountInPanel')}
                        />
                        <TextInput
                            block
                            className="order__input"
                            label="Количество слоёв"
                            placeholder="Введите нужное количество"
                            errors={errors}
                            {...register('amountOfLayers')}
                        />
                        <TextInput
                            block
                            className="order__input"
                            label="Толщина платы, мм"
                            placeholder="Введите нужный размер"
                            errors={errors}
                            {...register('thickness')}
                        />

                        <TextInput
                            block
                            className="order__input"
                            label="Ширина панели, мм"
                            placeholder="Введите нужный размер"
                            errors={errors}
                            {...register('panelSizeW')}
                        />

                        <TextInput
                            block
                            className="order__input"
                            label="Ширина платы, мм"
                            placeholder="Введите нужный размер"
                            errors={errors}
                            {...register('sizeW')}
                        />
                        <Select
                            block
                            className="order__input"
                            label="Толщина меди на внешних слоях, мкм"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('copperOutsideThickness')}
                        >
                            <option>-</option>
                            <option>18</option>
                            <option>35</option>
                            <option>70</option>
                            <option>105</option>
                            <option>140</option>
                            <option>175</option>
                        </Select>
                        <Select
                            block
                            className="order__input"
                            label="Отверстия открыты/закрыты"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('holes')}
                        >
                            <option>Открыты от маски</option>
                            <option>Закрыты от маски</option>
                            <option>Согласно GERBER-файлам</option>
                        </Select>
                        <Select
                            block
                            className="order__input"
                            label="Паяльная маска"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('solderMask')}
                        >
                            <option>Нет</option>
                            <option>Только TOP</option>
                            <option>Только BOT</option>
                            <option>С двух сторон</option>
                        </Select>

                        <Select
                            block
                            className="order__input"
                            label="Маркировка"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('marking')}
                        >
                            <option>Нет</option>
                            <option>Только TOP</option>
                            <option>Только BOT</option>
                            <option>С двух сторон</option>
                        </Select>
                    </Col>
                    <Col type={ColType.equal_sm} className="order__col_50_2">
                        <TextInput
                            block
                            className="order__input"
                            label="Количество панелей"
                            readOnly={true}
                            value={Number.isFinite(amountOfPanels) ? amountOfPanels : ''}
                        />
                        <Select
                            block
                            className="order__input"
                            label="Класс по IPC"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('ipcClass')}
                        >
                            <option>2</option>
                            <option>3</option>
                        </Select>
                        <Select
                            block
                            className="order__input"
                            label="Материал"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('material')}
                        >
                            <option>FR4 (TG135)</option>
                            <option>FR4 (TG150)</option>
                            <option>FR HighTG</option>
                            <option>Алюминий</option>
                            <option>Rogers</option>
                            <option>Полиимид</option>
                            <option>CEM-1</option>
                            <option>Иное</option>
                        </Select>

                        <TextInput
                            block
                            className="order__input"
                            label="Длина панели, мм"
                            placeholder="Введите нужный размер"
                            errors={errors}
                            {...register('panelSizeH')}
                        />

                        <TextInput
                            block
                            className="order__input"
                            label="Длина платы, мм"
                            placeholder="Введите нужный размер"
                            errors={errors}
                            {...register('sizeH')}
                        />
                        <Select
                            block
                            className="order__input"
                            label="Толщина меди на внутренних слоях, мкм"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('copperInsideThickness')}
                        >
                            <option>-</option>
                            <option>18</option>
                            <option>35</option>
                            <option>70</option>
                            <option>105</option>
                            <option>140</option>
                            <option>175</option>
                        </Select>
                        <Select
                            block
                            className="order__input"
                            label="Финишное покрытие"
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('finalCoat')}
                        >
                            <option>HASL</option>
                            <option>LeadFree HASL</option>
                            <option>Иммерсионное золото</option>
                            <option>Иммерсионное серебро</option>
                            <option>Иммерсионное олово</option>
                            <option>ENEPIG</option>
                            <option>Flash gold</option>
                            <option>Soft gold</option>
                            <option>Без покрытия</option>
                        </Select>

                        <Select
                            block
                            className="order__input"
                            label="Цвет паяльной маски"
                            disabled={!data.solderMask || data.solderMask === 'Нет'}
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('solderMaskColor')}
                        >
                            <option>Зеленая</option>
                            <option>Синяя</option>
                            <option>Черная</option>
                            <option>Красная</option>
                            <option>Желтая</option>
                            <option>Белая</option>
                            <option>Прозрачная</option>
                        </Select>

                        <Select
                            block
                            className="order__input"
                            label="Цвет маркировки"
                            disabled={!data.marking || data.marking === 'Нет'}
                            placeholder="Выберете из списка"
                            errors={errors}
                            {...register('markingColor')}
                        >
                            <option>Белая</option>
                            <option>Желтая</option>
                            <option>Черная</option>
                            <option>Зеленая</option>
                            <option>Красная</option>
                        </Select>
                    </Col>
                    <Col>
                        <TextInput
                            block
                            multiRow
                            className="order__textarea"
                            label="Дополнительная информация"
                            placeholder="Введите дополнительную информацию по заказу"
                            errors={errors}
                            {...register('info')}
                        />
                    </Col>
                </Row>
            </Col>
        </>
    );
};
