import React from 'react';
import { useStore } from 'effector-react';
import { loadPcbTech, pcbTechState } from '@/models/pcbTech';
import { Container, MobileContainer, Row } from '@/layout';
import { PcbDesktopTable } from '@/pages/PCBTech/blocks/PcbDesktopTable';
import { PcbMobileTable } from '@/pages/PCBTech/blocks/PcbMobileTable';
import { TopHeader } from '@/blocks';
import { PcbTypesBlock } from '@/blocks/PcbTypesBlock/PcbTypesBlock';
import './index.less';

export const PcbTech: React.FC = () => {
  const data = useStore(pcbTechState);

  React.useEffect(() => {
    loadPcbTech();
  }, []);

  return (
    <>
      <TopHeader title="ТЕХНОЛОГИЧЕСКИЕ ВОЗМОЖНОСТИ ПРОИЗВОДСТВ">
        <div className="pcb-tech__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <MobileContainer pcView={<PcbDesktopTable rows={data} />} mobileView={<PcbMobileTable />} />
        </Row>
        <PcbTypesBlock type="tech" />
      </Container>
    </>
  );
};
