import { api, DEFAULT_GET_PARAMS, ENDPOINTS } from '@/api/api';
import { Asset, ListItem, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

export type ContractsMountPageData = {
  mainInfo: string;
  automaticInfo: string;
  manualInfo: string;
  washingInfo: string;
  automaticCapabilities: ListItem[];
  manualCapabilities: ListItem[];
  assets: { data: ResponseType<Asset>[] };
};

const loadContractsMountPage = async () => {
  const data = await api.get<ResponseDataType<ContractsMountPageData>>(ENDPOINTS.pages.contractsMount, {
    params: DEFAULT_GET_PARAMS,
  });
  return getResponseData(data);
};

const contractsMount$ = fetchDataFactory({ defaultData: null, loader: loadContractsMountPage });

export { contractsMount$ };
