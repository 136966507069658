import React, {forwardRef} from "react";
import cn from 'classnames';
import './index.less';
import InputMask, {Props as InputMaskProps} from "react-input-mask";
import {FieldError} from "react-hook-form/dist/types/errors";

type TextInputProps = (React.InputHTMLAttributes<HTMLTextAreaElement | HTMLInputElement>) & {
    label?: string;
    multiRow?: boolean;
    block?: boolean;
    inputMaskProps?: InputMaskProps,
    errors?: Record<string, FieldError>
};
// eslint-disable-next-line react/display-name
export const TextInput = forwardRef<HTMLInputElement | HTMLTextAreaElement, TextInputProps>(({
                                                                                                 label,
                                                                                                 multiRow,
                                                                                                 className,
                                                                                                 block,
                                                                                                 inputMaskProps,
                                                                                                 errors,
                                                                                                 onBlur,
                                                                                                 onChange,
                                                                                                 ...props
                                                                                             }, ref) => {

    const wrapperClassName = cn(
            "text_input",
            className,
            {
                "text_input--block": block,
                "text_input--error": !!(props.name && errors?.[props.name])
            }
    );

    const Component = multiRow ? "textarea" : "input";

    return <div className={wrapperClassName}>
        {!!label && <div className="text_input__label">{label}</div>}
        {!!inputMaskProps && <InputMask {...inputMaskProps} onChange={onChange} onBlur={onBlur}>
            {    // @ts-ignore
                (inputProps) => <input ref={ref} {...props} {...inputProps}/>
            }
        </InputMask>}

        {/* @ts-ignore */}
        {!inputMaskProps && <Component ref={ref} onChange={onChange} onBlur={onBlur} {...props}/>}

        {props.name && errors?.[props.name] && <div className="text_input__error">
            {errors[props.name].message}
        </div>}
    </div>;

});