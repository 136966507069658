import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { contractsProject$ } from '@/models/contractsProjectPage';
import { List, TopHeader } from '@/blocks';
import './index.less';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType } from '@/types';

export const Project: React.FC = () => {
  const { data } = useStore(contractsProject$.store);

  useLoadData(contractsProject$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }

  return (
    <>
      <TopHeader
        title="ПРОЕКТИРОВАНИЕ ПЕЧАТНЫХ ПЛАТ
И ЭЛЕКТРОННЫХ МОДУЛЕЙ"
        className="contracts-project__header"
      >
        <div className="contracts-project__top-image" />
      </TopHeader>

      <Container>
        <Row>
          {data.mainInfo.split('\n').map((block, i) => (
            <Col type={ColType.equal_lg} key={i}>
              <Typography markdown>{block}</Typography>{' '}
            </Col>
          ))}
        </Row>

        <Row>
          <Col type={ColType.equal_lg}>
            <List items={data.features} header="ОСНОВНЫЕ ВОЗМОЖНОСТИ ОТДЕЛА РАЗРАБОТОК" />
          </Col>
          <Col type={ColType.equal_lg}>
            <Typography markdown className="contracts-project__secondary-info">
              {data.secondaryInfo}
            </Typography>
          </Col>
        </Row>
      </Container>
    </>
  );
};
