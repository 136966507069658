import { api, ENDPOINTS } from '@/api/api';
import { Asset, ResponseDataType, ResponseType } from '@/types';
import { getResponseData } from '@/utils';
import { fetchDataFactory } from '@/models/fetchData';

const loadPcbMaterials = async () => {
  const data = await api.get<
    ResponseDataType<{ materials: { group: string; files: { data: ResponseType<Asset>[] } }[] }>
  >(ENDPOINTS.pages.pcbMaterials, {
    params: { populate: 'materials.files' },
  });
  const res = getResponseData(data);
  return res.materials.map((v) => ({ ...v, files: v.files.data.map((f) => f.attributes) }));
};

const pcbMaterials$ = fetchDataFactory({ defaultData: [], loader: loadPcbMaterials });
export { pcbMaterials$ };
