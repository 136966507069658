import React from 'react';
import { useStore } from 'effector-react';
import { pcbLayered$ } from '@/models/pcbLayered';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType, TypographyFace } from '@/types';
import { chunkArray, getImagePropsFromResponse } from '@/utils';
import './layered.less';
import { PcbTypesBlock } from '@/blocks/PcbTypesBlock/PcbTypesBlock';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { pcbHeatSink$ } from '@/models/pcbHeatSink';

export const PcbHeatSink: React.FC = () => {
    const { data } = useStore(pcbHeatSink$.store);

    useLoadData(pcbHeatSink$.methods);

    if (!data) {
        return <Loader fullWidth />;
    }

    return (
        <Container>
            <Row>
                <Col type={ColType.equal_lg}>
                    <Typography variant={TypographyFace.header}>ПЛАТЫ СО ВСТРОЕННЫМ ТЕПЛООТВОДОМ</Typography>
                </Col>
            </Row>
            <Row wrap>
                <Col type={ColType.equal_lg}>
                    <Typography markdown>{data.content}</Typography>
                </Col>
                <Col type={ColType.equal_lg}>
                    <div className="pcb-flex__img-container">
                        {data.pictures.map((v) => {
                                    return <img key={v.id} {...getImagePropsFromResponse(v)} />;
                                }
                        )}
                    </div>
                </Col>
            </Row>
            <PcbTypesBlock type="types" />
        </Container>
    );
};
