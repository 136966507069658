import React from 'react';
import { ArrowBottom, ArrowUp, AvivLogo, CloseIcon, MenuIcon } from '@/icons';
import { IconButton, Link } from '@/components';
import { Accent, Route } from '@/types';
import cn from 'classnames';
import { mainRoutes } from '@/pages/routes';
import { Link as RouterLink } from 'react-router-dom';

export const MobileMenu: React.FC = () => {
    const [isShown, setIsShown] = React.useState(false);
    const [subMenu, setSubMenu] = React.useState<string[]>([]);

    const rootClasses = cn('menu__mobile-root', { [`menu__mobile-root-show-${isShown}`]: true });

    const handleMenuClick = () => {
        setIsShown((v) => !v);
    };

    const expandMenuToggle = (path: string) => {
        subMenu.includes(path) ? setSubMenu(subMenu.filter((v) => v !== path)) : setSubMenu([...subMenu, path]);
    };

    const renderMenu = (item: Route) => {
        if (item.hideInMenu) {
            return null;
        }

        return (
            <React.Fragment key={item.path}>
                <div className="menu__sub-item" >
                    <Link
                        to={`${item.path}`}
                        className={cn('menu__sub-item-link', {
                            'menu__sub-item-link-expanded': !mainRoutes.find((v) => v.path === item.path),
                        })}
                    >
                        {item.label}
                    </Link>
                    {item.children && !!item.path && (
                        <IconButton
                            className="menu__sub-item__button"
                            accent={Accent.light}
                            icon={item.path && subMenu.includes(item.path) ? ArrowUp : ArrowBottom}
                            onClick={() => expandMenuToggle(item.path as string)}
                        />
                    )}
                </div>
                {item.path &&
                    subMenu.includes(item.path) &&
                    item.children?.map((child) => {
                        return (
                            <React.Fragment key={child.path}>
                                <div className="menu__sub-item" key={child.path} onClick={handleMenuClick}>
                                    <Link to={`${child.path}`} className="menu__sub-item-link menu__sub-item-link-tree">
                                        {child.label}
                                    </Link>
                                    {child.children && !!child.path && (
                                        <IconButton
                                            className="menu__sub-item__button"
                                            accent={Accent.light}
                                            icon={child.path && subMenu.includes(child.path) ? ArrowUp : ArrowBottom}
                                            onClick={() => expandMenuToggle(child.path as string)}
                                        />
                                    )}
                                </div>
                                {child.path &&
                                    subMenu.includes(child.path) &&
                                    child.children?.map((v) => renderMenu(v))}
                            </React.Fragment>
                        );
                    })}
            </React.Fragment>
        );
    };

    return (
        <div className={cn('menu__mobile-container', { 'menu__mobile-container-fixed': isShown })}>
            <div className="menu__root menu__mobile-menu">
                <RouterLink to="/">
                    <AvivLogo className={isShown ? 'menu__mobile-logo' : ''} />
                </RouterLink>
                <IconButton
                    icon={isShown ? CloseIcon : MenuIcon}
                    accent={Accent.light}
                    className={cn('menu__mobile-btn', { 'menu__mobile-btn-close': isShown })}
                    onClick={handleMenuClick}
                />
            </div>
            <div className={rootClasses}>
                <div className="menu__mobile-box">{mainRoutes.map((item) => renderMenu(item))}</div>
            </div>
        </div>
    );
};
