import React from 'react';
import { useStore } from 'effector-react';
import { useLoadData } from '@/hooks/useLoadData';
import { Loader } from '@/components';
import { TopHeader } from '@/blocks';
import { Col, Container, Row, Typography } from '@/layout';
import { ColType } from '@/types';
import { contractsProtection$ } from '@/models/contractsProtectionPage';
import './index.less';

export const Protection: React.FC = () => {
  const { data } = useStore(contractsProtection$.store);

  useLoadData(contractsProtection$.methods);

  if (!data) {
    return <Loader fullWidth />;
  }
  return (
    <>
      <TopHeader title="ВЛАГОЗАЩИТА ПЕЧАТНЫХ УЗЛОВ, ЗАЛИВКА КОМПАУНДОМ" className="contracts-protection__header">
        <div className="contracts-protection__top-image" />
      </TopHeader>
      <Container>
        <Row>
          <Col type={ColType.equal_lg}>
            <Typography markdown>{data.info}</Typography>
          </Col>
          <Col type={ColType.equal_lg} />
        </Row>
      </Container>
    </>
  );
};
