import { Button } from './Button/Button';
import { BaseButtonProps } from './Button/types';
import { IconButton } from './IconButton/IconButton';
import { ForwardLink } from './ForwardLink/ForwardLink';
import { Link } from './Link/Link';
import { DetailesLink } from '@/components/DetailesLink/DetailesLink';
import { Loader } from './Loader/Loader';

export type { BaseButtonProps };
export { Button, IconButton, ForwardLink, Link, DetailesLink, Loader };
